import React from "react";
import {useLocation} from 'react-router-dom';

import {
    GlassCard,
} from 'gih_web_common';

import Logo from 'gih_web_common/assets/gih-logo-1818x268.png';

import {SignupWidget} from "../../common/signupWidget";


export default function SignInPage() {

    return (
        <div className="w-full flex justify-center items-center p-1" style={{ height: 'calc(100vh - 64px)' }}>
            <GlassCard width="max-w-md space-y-3">
                <div className="flex justify-center">
                    <img src={Logo} alt="" />
                </div>
                <div>
                    <div className="flex justify-between align-start">
                        <SignInTitle/>
                    </div>
                </div>
                <SignupWidget intro={<SignInIntro/>} />
            </GlassCard>
        </div>
    );
}

function SignInTitle() {

    const location = useLocation();

    if (location.state?.target?.match(/^\/fundraiser\/.+\/create$/)) {
        return <h2 className="text-3xl font-bold text-fgCard-primary">Sign in or sign up to create your fundraiser</h2>;
    } else if (location.state?.target?.match(/^\/fundraiser\/.+\/overview$/)) {
        return <h2 className="text-3xl font-bold text-fgCard-primary">Sign in to access your fundraiser</h2>;
    } else {
        return <h2 className="text-3xl font-bold text-fgCard-primary">Sign in to your existing donor account or create a new one</h2>;
    }
}

function SignInIntro() {

    const location = useLocation();

    if (location.state?.target?.match(/^\/fundraiser\/.+\/create$/)) {
        return <div className="text-sm font-bold mb-4 leading-relaxed">
            Verify your mobile number to start creating a fundraiser. If you haven't signed up already,
            we'll just need a few details to create your personal profile.
        </div>;
    } else if (location.state?.target?.match(/^\/fundraiser\/.+\/overview$/)) {
        return  <div className="text-sm font-bold mb-4 leading-relaxed">
            Verify your mobile number to sign in and access your fundraiser.
        </div>;
    } else {
        return <>
            <div className="text-sm font-bold mb-4 leading-relaxed">
                If you've already donated through Giving Is Human or have registered your mobile number
                in our app, then you can sign in to your profile here.
            </div>
            <div className="text-sm font-bold mb-4 leading-relaxed">
                If you haven't yet registered, verify your mobile number here to create your
                personal profile, see your donation history and fundraise for your favourite causes!
            </div>
        </>;
    }
}
