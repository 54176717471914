import {gql} from "@apollo/client";

import {campaignDetail} from "./common";


export const GET_CONFIG = gql`
    query GetConfig {
      getConfig {
        imageURLPrefix
        videoURLPrefix
        certURLPrefix
        storageBucket
        allowUserInterests
        allowGiving
        donation {
          minimumAmountGBP
          maximumAmountGBP
          smallAmountThresholdGBP
          smallAmountTipOptionsGBP
          defaultTipOptionGBP
          tipPercentOptions
          smallAmountTipOptions {
            upToGBP
            amountsGBP
            defaultGBP
          }
        }
        campaign {
          nameMaxLength
          descriptionMaxLength
          keywordsMax
        }
        fundraiser {
          minimumTargetGBP
          maximumTargetGBP
        }
      }
    }
`;

export const GET_CAMPAIGN_BY_DISTANCE = gql`
    query GetFilteredCampaignsByDistance($geohash: String!, $categories: [String!]!, $after: DateTime!) {
        getFilteredCampaignsByDistance(geohash: $geohash, categories: $categories, after: $after) {
            ${campaignDetail}
            fundraiserId
            distance
        }
    }
`;

export const GET_CAMPAIGN_BY_SCORE = gql`
    query GetFilteredCampaignsByScore {
        getFilteredCampaignsByScore {
            spotlight {
                ${campaignDetail}
                distance
            }
            scored {
                ${campaignDetail}
                distance
            }
        }
    }
`;

export const CREATE_DONATION_INTENT_NO_SIGNUP = gql`
    mutation CreateDonationIntentNoSignUp($intent: DonationIntentNoSignUp!) {
      createDonationIntentNoSignUp(intent: $intent) {
        paymentIntent
        publishableKey
      }
    }
`;

export const CREATE_DONATION_INTENT = gql`
    mutation createDonationIntent($intent: DonationIntent!) {
      createDonationIntent(intent: $intent) {
        paymentIntent
        publishableKey
      }
    }
`;

export const GET_CAMPAIGN_BY_ID = gql`
    query FindCampaignById($id: ID!) {
        findCampaignById(id: $id) {
            status
            campaign {
                ${campaignDetail}
                fundraiserId
            }
            fundraisers {
                _id
                createdAt
                name
                imageURL
                donations {
                    uniqueDonors
                    total
                }
            }
        }
    }
`;

export const GET_CHARITY_BY_ID = gql`
    query FindCharityById($id: ID!) {
      findCharityById(id: $id) {
        _id
        name
        profilePicture
        logoPicture
        fullDescription
        briefDescription
        address {
          line1
          line2
          streetName
          townCity
          postcode
        }
        geohash
        locationName
        keywords
        website
        registration
        giftAidEligible
      }
    }
`;

export const GET_THANK_YOU = gql`
    query findDonationThankYou($campaignId: ID!, $paymentIntent: String!) {
        findDonationThankYou(campaignId: $campaignId, paymentIntent: $paymentIntent) {
            donorFundraiserCampaignId
            thankYou {
                _id
                createdAt
                type
                postedBy
                heading
                videoName
                imageName
                text
            }
        }
    }
`;

export const INTERACTION_EVENT = gql`
    mutation InteractionEvent($context: String!, $what: String!, $campaignId: ID) {
        interactionEvent(context: $context, what: $what, campaignId: $campaignId)
    }
`;
