import {useState, useEffect} from 'react';
import {useMediaQuery} from "react-responsive";

import {useQuery} from '@apollo/client';

import {OnLoadViewer, Modal, CharityDetail} from 'gih_web_common';

import {GET_CHARITY_BY_ID} from '../../utils/graphQL/queries';
import {logScreenViewEvent, logActionGraphQLFailure} from "../../utils/analytics";
import {ACTIONS, SCREEN_NAME, SCREEN_CLASS} from "../../utils/analyticsConstants";


export function CharityDetailModal({onDismiss, config, id}) {

    const isNarrow = useMediaQuery({ query: '(max-width: 800px)' });
    const [charity, setCharity] = useState(null);

    const {loading: charityQueryInProgress} = useQuery(GET_CHARITY_BY_ID, {
        variables: {
            id: id,
        },
        onCompleted: data => {
            setCharity(data.findCharityById);
        },
        onError: e => {
            logActionGraphQLFailure(ACTIONS.getCharity, e);
        },
    });

    useEffect(() => {
        logScreenViewEvent(SCREEN_NAME.charityDetail, SCREEN_CLASS.charity);
    }, []);

    return (
        <Modal onDismiss={onDismiss} title={charity?.name}>
            <OnLoadViewer loading={charityQueryInProgress}>
                { charity &&
                <CharityDetail config={config} charity={charity} isNarrow={isNarrow} />
                }
            </OnLoadViewer>
        </Modal>
    );
}
