import React from "react";
import {useNavigate, Link} from 'react-router-dom';
import {useSelector, useDispatch} from "react-redux";

import {signOut} from 'firebase/auth';

import {GlassCard, CustomButton, buttonStyles} from 'gih_web_common';

import {auth} from "../../utils/firebase";
import {company} from "../../utils/legals/constants";


export default function SignOutPage() {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const loginState = useSelector(state => state.loginState);

    function onSignOut() {
        signOut(auth)
            .then(() => {
                console.log('Signed out');
                dispatch({ type: "CLEAR_AUTH" });
            });
    }

    return (
        <div className="w-full flex justify-center items-center p-1" style={{ height: 'calc(100vh - 64px)' }}>
            { loginState === 'LoggedOut' &&
            <GlassCard width="space-y-4">
                <div className="text-2xl font-bold text-center">You are signed out</div>
                <div className="w-full h-full flex grid sm:grid-cols-2 items-center justify-items-center gap-4">
                    <CustomButton className={buttonStyles.blackLg} onClick={() => navigate('/sign-in')}>Sign in again</CustomButton>
                    <Link className={buttonStyles.primaryLg} to={company.websiteURL}>Explore our website</Link>
                </div>
            </GlassCard>
            }
            { loginState === 'LoggedIn' &&
            <GlassCard width="space-y-4">
                <div className="text-2xl font-bold text-center">Are you sure you want to logout?</div>
                <div className="w-full h-full flex grid sm:grid-cols-2 items-center justify-items-center gap-4">
                    <CustomButton className={buttonStyles.blackLg} onClick={onSignOut}>Yes - log me out</CustomButton>
                    <CustomButton className={buttonStyles.primaryLg} onClick={() => navigate('/myCauses')}>No - take me back to my causes</CustomButton>
                </div>
            </GlassCard>            
            }
        </div>
    );
}
