import {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {useMutation} from '@apollo/client';

import {GlassCard, GroupBox, FormWrapper, FormInput, FormCheckBox, FormErrorText, FormPrimaryButton, Modal, ActionLink, TextRender} from 'gih_web_common';

import {logScreenViewEvent, logActionSuccess, logActionGraphQLFailure, logFormValidationFailure} from "../../utils/analytics";
import {SCREEN_NAME, SCREEN_CLASS} from "../../utils/analyticsConstants";
import {APP_UPDATE_USER_DETAILS, APP_ADD_USER} from '../../utils/graphQL/personal';

import {individualTsAndCs} from '../../utils/legals/individualTsAndCs';
import {privacyPolicy} from '../../utils/legals/privacyPolicy';
import {cookiePolicy} from '../../utils/legals/cookiePolicy';

const genderFlags = [
    {
        attr: 'female',
        label: 'Female'
    }, {
        attr: 'male',
        label: 'Male'
    }, {
        attr: 'nonBinary',
        label: 'Non-binary'
    }, {
        attr: 'transgender',
        label: 'Transgender'
    }, {
        attr: 'intersex',
        label: 'Intersex'
    }, {
        attr: 'preferNotToSay',
        label: 'Prefer not to say'
    }
];

function toMonthStr(when) {
    return when?.toLocaleDateString('en-us', {year: 'numeric', month: '2-digit'});
}

export default function MyProfilePage() {

    const dispatch = useDispatch();

    const authUser = useSelector(state => state.authUser);
    const user = useSelector(state => state.user);
    const lastDonation = useSelector(state => state.lastDonation);

    const [monthOfBirth, setMonthOfBirth] = useState(null);
    const [modified, setModified] = useState(false);
    const [modal, setModal] = useState(null);

    const [addUser] = useMutation(APP_ADD_USER);
    const [updateUserDetails] = useMutation(APP_UPDATE_USER_DETAILS);

    function onSubmit(values) {

        return new Promise((resolve, reject) => {

            if (!values.TsAndCsAccepted)
                reject({ TsAndCsAccepted: 'You cannot proceed without agreeing to the Terms & Conditions' });

            const monthOfBirthStr = toMonthStr(monthOfBirth);

            if (!monthOfBirthStr)
                reject("Please select your month of birth");

            const details = {
                name: values.firstName.trim() + ' ' + values.lastName.trim(),
                age: monthOfBirthStr,
                postcode: values.postcode,
                profilePicture: user?.profilePicture ?? null,
                interests: user?.interests ?? [],
                acceptedTsAndCs: individualTsAndCs.version,
                gender: {},
            };

            genderFlags.forEach(flag => { details.gender[flag.attr] = values[`gender_${flag.attr}`]; });

            if (user) {
                resolve(updateUserDetails({
                    variables: {
                        firebaseId: user.firebaseId,
                        details: details,
                    },
                })
                .then(r => {
                    console.log('User details were updated', r.data.appUpdateUserDetails);
                    dispatch({type: "SET_USER", payload: r.data.appUpdateUserDetails});
                    setModified(false);
                }));
            } else {
                resolve(addUser({
                    variables: {
                        firebaseId: authUser.uid,
                        details: details,
                        paymentIntent: lastDonation?.paymentIntent,
                    },
                })
                .then(r => {
                    console.log('User was created', r.data.appAddUser);
                    dispatch({type: "SET_USER", payload: r.data.appAddUser});
                    setModified(false);
                }));
            }
        });
    }

    function getFormValues() {
        const values = {
            firstName: user?.firstName ?? '',
            lastName: user?.lastName ?? '',
            postcode: user?.postcode ?? '',
            TsAndCsAccepted: user?.acceptedTsAndCs === individualTsAndCs.version,
        };
        genderFlags.forEach(flag => { values[`gender_${flag.attr}`] = user?.gender[flag.attr] ?? false; });
        return values;
    }

    useEffect(() => {
        if (!monthOfBirth && user?.age) {
            const parts = user.age.split('/');
            setMonthOfBirth(new Date(parseInt(parts[1]), parseInt(parts[0])-1));
        }
    }, [monthOfBirth, user?.age]);

    useEffect(() => {
        logScreenViewEvent(SCREEN_NAME.myProfile, SCREEN_CLASS.user);
    }, []);

    return (
        <div className="mx-1 my-2 sm:my-4">
            <GlassCard width="max-w-lg space-y-3">
                <h2 className="text-3xl font-bold text-fgCard-primary">{user ? "Your profile" : "Create your profile"}</h2>
                <FormWrapper
                    initialValues={getFormValues()}
                    setModified={setModified}
                    onSubmit={onSubmit}
                    onValidationFailure={logFormValidationFailure}
                    className="space-y-3"
                >
                    <div className="flex w-full space-x-3">
                        <FormInput
                            id="firstName"
                            name="firstName"
                            type="text"
                            autoComplete="given-name"
                            placeholder="first name"
                            label="Your first name"
                            required
                            rootClassName="w-full"
                        />
                        <FormInput
                            id="lastName"
                            name="lastName"
                            type="text"
                            autoComplete="family-name"
                            placeholder="last name"
                            label="Your last name"
                            required
                            rootClassName="w-full"
                        />
                    </div>

                    <div className="text-xs text-fgCard-secondary">
                        Postcode information is collected for analytical purposes and to enable us to inform you of new charitable causes in your area.
                        This information is not visible to the public nor to the non-profit organisations receiving your donations.
                    </div>

                    <FormInput
                        id="postcode"
                        name="postcode"
                        type="text"
                        autoComplete="postal-code"
                        placeholder="postcode"
                        label="Your postcode"
                        required
                        rootClassName="w-full"
                    />

                    <div className="text-xs text-fgCard-secondary">
                        Gender and age information is collected for analytical purposes only.
                        This information is not visible to the public nor to the charities to which you make donations.
                    </div>

                    <DatePicker
                        id="monthOfBirth"
                        selected={monthOfBirth}
                        onChange={date => setMonthOfBirth(date)}
                        dateFormat="MM/yyyy"
                        placeholderText="month of birth"
                        showMonthYearPicker
                        showFullMonthYearPicker
                        autoComplete="off"
                        wrapperClassName="w-[12em]"
                        className="text-fg-default bg-bg-paper"
                    />

                    <GroupBox>
                        <div className="text-sm text-fgCard-secondary">Which of the following most accurately describe(s) you?</div>
                        { genderFlags.map(flag =>
                        <FormCheckBox
                            key={flag.attr}
                            id={`gender_${flag.attr}`}
                            name={`gender_${flag.attr}`}
                            label={flag.label}
                        />
                        )}
                    </GroupBox>

                    <GroupBox>
                        <FormCheckBox
                            id="TsAndCsAccepted"
                            name="TsAndCsAccepted"
                            label={
                                <div className="text-base">
                                    <span>I agree to the </span>
                                    <ActionLink onClick={() => setModal(individualTsAndCs)}>Terms & Conditions</ActionLink>
                                    <span> for individual donor use of the Giving Is Human platform</span>
                                </div>
                            }
                        />
                    </GroupBox>

                    <div className="w-full">
                        <FormErrorText/>
                        <FormPrimaryButton type="submit" disabled={!modified && toMonthStr(monthOfBirth) === user?.age}>{user ? "Save changes" : "Create my profile"}</FormPrimaryButton>
                    </div>
                </FormWrapper>
            </GlassCard>

            { modal &&
            <Modal onDismiss={() => setModal(null)} title={modal.title}>
                { modal.what === 'privacyPolicy' &&
                <ActionLink onClick={() => setModal(cookiePolicy)}>Cookie Policy</ActionLink>
                }
                { modal.what === 'cookiePolicy' &&
                <ActionLink onClick={() => setModal(privacyPolicy)}>Privacy Policy</ActionLink>
                }
                <TextRender what={modal.body} />
            </Modal>
            }
        </div>
    );
}
