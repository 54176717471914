import {useState} from 'react';

import {inputStyles, GroupBox, Checkbox} from 'gih_web_common';


const titleOptions = [
    '--',
    'Mr',
    'Miss',
    'Ms',
    'Mrs',
    'Dr',
    'Other'
];

function initialTitleOpt(title) {
    if (title === null) {
        return null;
    } else if (titleOptions.find(opt => opt === title)) {
        return title;
    } else {
        return 'Other';
    }
}

export function MessageScreen({ message, setMessage, name, setName, hideDonorName, setHideDonorName }) {

    const [titleOpt, setTitleOpt] = useState(initialTitleOpt(name.title));

    function onTitleMenuSelection(opt) {
        setTitleOpt(opt);
        if (opt === '--')
            setName({ ...name, title: null });
        else if (opt === 'Other')
            setName({ ...name, title: '' });
        else
            setName({ ...name, title: opt });
    }

    return (
        <GroupBox>
            <h1 className="block mb-1 font-semibold">Your name</h1>
            <div>
                <label className="block mb-1 font-semibold" htmlFor="titleSelect">Title</label>
                <div className="flex flex-row gap-2">
                    <select value={titleOpt ?? '--'} id="titleSelect" onChange={e => onTitleMenuSelection(e.target.value)} className={inputStyles.select}>
                        { titleOptions.map(option => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                    { titleOpt === 'Other' &&
                    <input
                        type="text"
                        id="title"
                        className={`w-[8em] ${inputStyles.text}`}
                        value={name.title ?? ''}
                        onChange={(e) => setName({ ...name, title: e.target.value })}
                    />
                    }
                </div>
            </div>
            <div>
                <label className="block mb-1 font-semibold" htmlFor="firstName">First name</label>
                <input
                    type="text"
                    id="firstName"
                    className={`w-full ${inputStyles.text}`}
                    value={name.first ?? ''}
                    onChange={(e) => setName({ ...name, first: e.target.value })}
                />
            </div>
            <div>
                <label className="block mb-1 font-semibold" htmlFor="lastName">Last name</label>
                <input
                    type="text"
                    id="lastName"
                    className={`w-full ${inputStyles.text}`}
                    value={name.last?? ''}
                    onChange={(e) => setName({ ...name, last: e.target.value })}
                />
            </div>

            <div>
                <label className="block mb-1 font-semibold" htmlFor="message">Your message of support (optional)</label>
                <textarea
                    id="message"
                    rows="6"
                    value={message ?? ''}
                    onChange={(e) => setMessage(e.target.value)}
                    className={`resize-none w-full ${inputStyles.text}`}
                    placeholder="Write your message..."
                />
            </div>

            <div>
                <Checkbox
                    id="hideDonorName"
                    className="mr-2"
                    isChecked={hideDonorName}
                    onChange={() => setHideDonorName(!hideDonorName)}
                    label="Don't show my name publicly"
                />
            </div>
        </GroupBox>
    );
}
