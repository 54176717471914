import React from 'react';

import {GroupBox, GroupBox2, CustomButton, inputStyles, buttonStyles, WebLink} from 'gih_web_common';

import {SignupWidget} from "../../common/signupWidget";
import {company} from "../../utils/legals/constants";


export function PromoteSignup() {

    return (
        <>
            <GroupBox>
                <div className="space-y-3 text-start">
                    <div className="font-bold text-xl">Want to start fundraising for this cause?</div>
                    <div className="font-bold text-base">...or simply want to keep track of all your donations in one place?</div>
                    <GroupBox2>
                        <div>You'll love all the benefits of creating your own Giving Is Human profile...</div>
                        <ul className="list-disc space-y-2 md:text-md text-sm ml-4">
                            <li>Tell your story - start and share a fundraiser with your own video message!</li>
                            <li>No need to fill out your details each time you donate</li>
                            <li>Keep a track of all your donations</li>
                            <li>Download an annual statement to simplify claiming tax relief where applicable</li>
                        </ul>
                    </GroupBox2>
                    <GroupBox2>
                        <div className="font-bold leading-relaxed">Verify your mobile number now to create your profile using the details you've already given us...</div>
                        <div className="text-sm font-bold leading-relaxed">Already got a profile? Sign in and we'll add this donation to your history.</div>
                        <SignupWidget/>
                    </GroupBox2>
                </div>
            </GroupBox>

            <GroupBox>
                <div className="flex justify-center text-xl font-bold">OR</div>
                <div className="text-sm mx-auto">Want to see more before you sign up?</div>
                <div className="flex justify-center">
                    <WebLink className={buttonStyles.blackLg} href={`${company.websiteURL}/donors`}>
                        Discover more causes near you
                    </WebLink>
                </div>
            </GroupBox>
        </>
    );
}
