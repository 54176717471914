import React, { useEffect, useState } from 'react';

import {Modal, GroupBox, GroupBox2, ActionLink, InputMonetaryAmount, buttonStyles, inputStyles} from 'gih_web_common';

import {TotalAmountWidget, CampaignSummaryWidget} from '../../common/donationWidgets';
import {company} from "../../utils/legals/constants";


function buttonStyle(selected) {
    return selected ? buttonStyles.primaryLgNarrow : buttonStyles.outlineLgNarrow;
}

export function DonationAmountScreen({campaign, config, donationAmount, setDonationAmount, voluntaryAmount, setVoluntaryAmount}) {

    const [chargesVisible, setChargesVisible] = useState(false);
    const [tipping, setTipping] = useState(null);
    const [selectedSliderValue, setSelectedSliderValue] = useState(2);
    const [selectedSmallTipGBP, setSelectedSmallTipGBP] = useState(null);
    const [otherAmountGBP, setOtherAmountGBP] = useState(3);

    const fixedTippingOption = config.donation.smallAmountTipOptions.find(option => donationAmount <= option.upToGBP * 100);

    useEffect(() => {
        switch (tipping?.type) {
            case 'other':
                setVoluntaryAmount(otherAmountGBP * 100);
                break;
            case 'percentage':
                setVoluntaryAmount(Math.floor((donationAmount * config.donation.tipPercentOptions[selectedSliderValue]) / 100));
                break;
            case 'fixed':
                setVoluntaryAmount(selectedSmallTipGBP * 100);
                break;
            default:
                break;
        }
    }, [
        setVoluntaryAmount,
        config.donation,
        donationAmount,
        tipping,
        selectedSliderValue,
        selectedSmallTipGBP,
        otherAmountGBP,
    ]);

    useEffect(() => {
        if (donationAmount > config.donation.maximumAmountGBP * 100 || donationAmount < config.donation.minimumAmountGBP * 100) {
            setTipping(null);
        } else if (fixedTippingOption && (!tipping || tipping.type === 'percentage' || (tipping.type === 'fixed' && tipping.option.upToGBP !== fixedTippingOption.upToGBP))) {
            setTipping({
                type: 'fixed',
                option: fixedTippingOption
            });
            if (!fixedTippingOption.amountsGBP.find(amount => amount === selectedSmallTipGBP))
                setSelectedSmallTipGBP(fixedTippingOption.defaultGBP);
        } else if (!fixedTippingOption && (!tipping || tipping.type === 'fixed')) {
            setTipping({
                type: 'percentage',
            });
        }
    }, [
        fixedTippingOption,
        selectedSmallTipGBP,
        tipping,
        donationAmount,
        config.donation,
    ]);

    return (
        <>
            <CampaignSummaryWidget campaign={campaign} config={config} />

            <GroupBox>
                <GroupBox2>
                    <div className="text-2xl sm:text-4xl font-bold">Donation amount</div>
                    <InputMonetaryAmount
                        id="amount"
                        placeholder="My donation"
                        amount={donationAmount}
                        setAmount={setDonationAmount}
                        maxGBP={config.donation.maximumAmountGBP*2}
                        minGBP={0}
                    />
                </GroupBox2>
                { tipping &&
                <GroupBox2>
                    <h2 className="text-2xl sm:text-4xl font-bold">Choose your voluntary contribution</h2>
                    <p className="text-sm text-fgCard-secondary">
                        Giving Is Human actively promotes local causes. Adding a voluntary contribution
                        on top of your donation helps us continue to raise awareness of causes in your area.
                    </p>
                    <p className="text-sm text-fgCard-secondary">
                        Find out about our charges and how we use your
                        contribution <ActionLink onClick={() => setChargesVisible(true)}>here</ActionLink>.
                    </p>
                    <div className="flex items-center justify-center space-x-4 w-fit mx-auto">
                        { tipping.type === 'fixed' &&
                            tipping.option.amountsGBP.map(option => (
                                <button
                                    key={option}
                                    onClick={() => setSelectedSmallTipGBP(option)}
                                    className={buttonStyle(option === selectedSmallTipGBP)}
                                >
                                    {`£${option}`}
                                </button>
                            ))
                        }
                        { tipping.type === 'percentage' &&
                            <>
                                <button className={buttonStyle(true)}>
                                    {`${config.donation.tipPercentOptions[selectedSliderValue]}%`}
                                </button>
                                <div className="flex-1">
                                    <input
                                        type="range"
                                        min={0}
                                        max={config.donation.tipPercentOptions.length - 1}
                                        step={1}
                                        value={selectedSliderValue}
                                        onChange={event => setSelectedSliderValue(event.target.value)}
                                        className={`w-full ${inputStyles.range}`}
                                    />
                                </div>
                            </>
                        }
                        { tipping.type === 'other' &&
                            <button className={buttonStyle(false)} onClick={() => setTipping(fixedTippingOption ? { type: 'fixed', option: fixedTippingOption } : { type: 'percentage' })}>
                                Recommended amounts
                            </button>
                        }
                    </div>
                    <div className="flex flex-row mx-auto gap-3 items-center">
                        <button className={buttonStyle(tipping.type === 'other')} onClick={() => setTipping({ type: 'other' })}>
                            Other amount
                        </button>
                        { tipping.type === 'other' &&                            
                            <InputMonetaryAmount
                                id="voluntary"
                                amount={otherAmountGBP*100}
                                setAmount={amount => setOtherAmountGBP(Math.floor(amount/100))}
                                maxGBP={config.donation.maximumAmountGBP/4}
                                minGBP={0}
                                showZero={true}
                                rootClassName="w-[12em] sm:w-[18em]"
                            />
                        }
                    </div>
                </GroupBox2>
                }
            </GroupBox>

            { tipping &&
            <TotalAmountWidget
                donationAmount={donationAmount}
                voluntaryAmount={voluntaryAmount}
            />
            }
            
            { chargesVisible &&
            <Modal
                onDismiss={() => setChargesVisible(false)}
                title="How much do we charge for processing your donation?"
            >
                <div>We <strong>do not charge a subscription</strong> to the charities and non-profits using our services.</div>
                <div>When you make a donation to a UK based registered charity or non-profit organisation through our platform, we take a transaction
                fee of <strong>1.9% + 20p</strong> from the amount donated. We use this money to cover transaction processing costs and
                administration, in line with industry standards. Additionally, we will deduct a charge of £2 from the first donation received by
                each charity in a calendar month to cover the active account fee levied by Stripe.</div>
                <div>We do not list fundraising campaigns for organisations based outside of the UK.</div>
                <div>When you add GiftAid to your donation and the charity opts to use our service to recover that GiftAid from HMRC, we
                charge <strong>2%</strong> of the GiftAid amount.</div>
                <div>When you add a voluntary contribution, we use that money to cover the costs of developing and running the platform
                and to enable us to promote our App and the campaigns we list through social media and other channels.</div>
                <div className="text-fgCard-secondary text-sm">© {company.name} 2024, registered in England and Wales no. {company.registrationNumber}</div>
            </Modal>
            }
        </>
    );
}
