import {useEffect, useState} from 'react';
import {useParams, useSearchParams, Link} from 'react-router-dom';
import {isMobile} from 'react-device-detect';
import {useMediaQuery} from "react-responsive";
import {useSelector, useDispatch} from "react-redux";
import { useNavigate } from 'react-router-dom';

import {useQuery} from "@apollo/client";

import {GlassCard, GroupBox, AppStoreBadges, buttonStyles, OnLoadViewer, VideoVCentre, ImageVCentre} from 'gih_web_common';

import {logActionGraphQLFailure, logActionSuccess, logScreenViewEvent} from "../../utils/analytics";
import {SCREEN_NAME, SCREEN_CLASS, ACTIONS} from "../../utils/analyticsConstants";
import {GET_CONFIG, GET_CAMPAIGN_BY_ID, GET_THANK_YOU} from "../../utils/graphQL/queries";

import ThankYou from "../../images/thank-you.svg";
import {ShareOrCopyButton} from "../../common/share";

import {PromoteSignup} from "./signup";


export default function DonationPage() {

    const navigate = useNavigate();

    const user = useSelector(state => state.user);
    const isNarrow = useMediaQuery({ query: '(max-width: 800px)' });

    const {campaignId} = useParams();
    const [searchParams] = useSearchParams();

    const result = searchParams.get('redirect_status');
    const paymentIntent = searchParams.get('payment_intent');

    const dispatch = useDispatch();

    const { loading: configInProgress, data: configData, error: configError } = useQuery(GET_CONFIG);

    const {loading, data, error} = useQuery(GET_THANK_YOU, {
        variables: {
            campaignId: campaignId,
            paymentIntent: paymentIntent,
        },
    });

    const config = configData?.getConfig;
    const thankYou = data?.findDonationThankYou.thankYou;
    const donorFundraiserCampaignId = data?.findDonationThankYou.donorFundraiserCampaignId;

    useEffect(() => {
        logScreenViewEvent(SCREEN_NAME.donationSuccess, SCREEN_CLASS.donation);
        if (paymentIntent) {
            dispatch({type: "SET_LAST_DONATION", payload: {
                paymentIntent: paymentIntent,
            }});
        }
    }, []);

    return (
        <div className="p-1 sm:p-2">
            { result === 'succeeded' &&
            <GlassCard width="max-w-4xl space-y-3">
                <GroupBox>
                    <OnLoadViewer loading={loading || configInProgress} error={error ?? configError}>
                        { thankYou ?
                        <div className="flex flex-col justify-center space-y-2 sm:space-y-3">
                            <div className="text-center text-lg font-bold">Your payment was successful!</div>
                            <div className="w-full text-fgCard-primary text-center text-4xl sm:text-6xl font-bold">Thank you!</div>
                            <div className="text-xs sm:text-sm mx-auto">A personal message from {thankYou.postedBy}</div>
                            <div className="mx-auto">
                                { thankYou.videoName ?
                                <VideoVCentre rmtPrefix={config.imageURLPrefix} rmt={thankYou.videoName} what={isNarrow ? "primaryImageMedium" : "primaryImageLarge"} autoPlay={true} />
                                :
                                <ImageVCentre rmtPrefix={config.imageURLPrefix} rmt={thankYou.videoName} what={isNarrow ? "primaryImageMedium" : "primaryImageLarge"} />
                                }
                            </div>
                            <div className="flex flex-col sm:flex-row gap-2">
                                <ShareCampaignButton campaignId={campaignId} />
                                { donorFundraiserCampaignId ?
                                <button className={buttonStyles.blueLg} onClick={() => navigate(`/fundraiser/${donorFundraiserCampaignId}/overview`)}>Go to your fundraiser</button>
                                : user ?
                                <button className={buttonStyles.blueLg} onClick={() => navigate(`/fundraiser/${campaignId}/create`)}>Start fundraising for this cause</button>
                                :
                                null
                                }
                            </div>
                        </div>
                        :
                        <>
                            <div className="flex justify-center">
                                <img src={ThankYou} alt="Thank you" className="h-[200px] sm:h-[300px]" />
                            </div>
                            <div className="mx-auto space-y-2 sm:space-y-3 text-center">
                                <div>Your payment was successful.</div>
                                <div className="w-full text-fgCard-primary text-center text-4xl sm:text-6xl font-bold">
                                    Thank you!
                                </div>
                                <ShareCampaignButton campaignId={campaignId} />
                            </div>
                        </>
                        }
                    </OnLoadViewer>
                </GroupBox>

                { (user || process.env.REACT_APP_ALLOW_LOGIN !== 'true') ? <PromoteApp/> : <PromoteSignup/> }
            </GlassCard>
            }
        </div>
    );
}

function ShareCampaignButton({ campaignId }) {

    const [campaign, setCampaign] = useState(null);

    useQuery(GET_CAMPAIGN_BY_ID, {
        variables: {
            id: campaignId
        },
        onCompleted: data => {
            const campaign = data.findCampaignById?.campaign;
            setCampaign(campaign);
        },
        onError: e => {
            logActionGraphQLFailure(ACTIONS.getCampaign, e);
        }
    });

    return (
        <ShareOrCopyButton
            action={ACTIONS.shareCampaign}
            details={campaign ? {
                title: 'Take a look at this great opportunity to help others!',
                text: `I've just donated towards "${campaign.name}" through Giving Is Human.`,
                url: campaign.dynamicLink,
            } : null}
        />
    );
}
                
function PromoteApp() {
    return (
        <>
            <GroupBox>
                <AppStoreBadges />

                <h3 className="md:text-xl text-md font-bold text-center">
                    { isMobile ? 'Touch a store icon above - get the Giving Is Human app now'
                    : 'Search "Giving Is Human" now on your phone - get the app'} -
                    build your connection with more local causes!
                </h3>

                <div className="space-y-2 text-start">
                    <div className="font-semi">You'll love all the benefits of giving your support through the app - just download and sign in using your mobile number.</div>
                    <ul className="list-disc space-y-2 md:text-md text-sm ml-4">
                        <li>Search and follow causes near you - see where they have an impact on the map</li>
                        <li>Get notified when we list new causes in your area and receive updates from those you've already supported</li>
                        <li>Donate whenever you like or setup a regular monthly donation</li>
                        <li>See our unique "thank you" videos that recognise the importance of your support</li>
                        <li>See a record of all your donations and download an annual statement to simplify claiming tax relief where applicable</li> 
                    </ul>
                </div>
            </GroupBox>

            <GroupBox>
                <div className="flex justify-center text-xl font-bold">OR</div>
                <div className="text-sm mx-auto">Continue using the web version for now</div>
                <div className="flex justify-center">
                    <Link className={buttonStyles.blackLg} to="/search">
                        Discover more causes near you
                    </Link>
                </div>
            </GroupBox>
        </>
    )
}
