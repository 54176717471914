import {useState} from "react";
import {useParams, useNavigate} from 'react-router-dom';

import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';

import {useQuery, useMutation} from '@apollo/client';

import {
    OnLoadViewer,
    GlassCard,
    GroupBox,
    buttonStyles,
    ImageVCentre,
    ImagePlaceholder,
    BrokenMediaPlaceholder,
    dateToStringRelative,
    dateToStringNoTime,
    CampaignProgressBar,
} from 'gih_web_common';

import { GET_OWN_FUNDRAISER, GET_OWN_FUNDRAISER_UPDATES, DELETE_FUNDRAISER_UPDATE, evictAllDependentOnCampaignUpdates } from '../../utils/graphQL/personal';
import { GET_CONFIG, GET_CAMPAIGN_BY_ID } from '../../utils/graphQL/queries';
import {SCREEN_NAME, SCREEN_CLASS, ACTIONS} from "../../utils/analyticsConstants";

import {ShareOrCopyButton} from '../../common/share';
import {InAidOfSummaryWidget} from '../../common/donationWidgets';

import {CreateOrEditFundraiserUpdate, modelUpdate} from './update';


export default function FundraiserOverviewPage() {

    const {campaignId} = useParams();
    const navigate = useNavigate();

    const [queryError, setQueryError] = useState(null);
    const [fundraiser, setExisting] = useState(null);
    const [campaign, setCampaign] = useState(null);
    const [thanks, setThanks] = useState(null);
    const [updates, setUpdates] = useState([]);
    const [currentUpdate, setCurrentUpdate] = useState(null);

    const { loading: configLoading, data: configData, error: configError } = useQuery(GET_CONFIG);
    const config = configData?.getConfig;

    const { loading: campaignLoading, error: campaignError } = useQuery(GET_CAMPAIGN_BY_ID, {
        variables: {
            id: campaignId
        },
        onCompleted: data => {
            const detail = data.findCampaignById;
            setCampaign(detail ? {
                ...detail.campaign,
                endDate: new Date(detail.campaign.endDate),
                status: detail.status,
            } : null);
        },
    });

    const {loading, error} = useQuery(GET_OWN_FUNDRAISER, {
        variables: {
            campaignId: campaignId,
        },
        notifyOnNetworkStatusChange: true,
        onCompleted: data => {
            setExisting(data.findOwnFundraiserByCampaignId);
            setQueryError(data.findOwnFundraiserByCampaignId ? null: new Error(`Sorry - we couldn't find that fundraiser
            - you may have used an out-of-date link - please select "My causes" to see your current fundraisers.`));
        }
    });

    const {loading: loadingUpdates, error: updatesError} = useQuery(GET_OWN_FUNDRAISER_UPDATES, {
        variables: {
            campaignId: campaignId,
            type: 'thanks',
        },
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setThanks(data.findOwnFundraiserUpdates?.[0])
    });

    const {loading: loadingThanks, error: thanksError} = useQuery(GET_OWN_FUNDRAISER_UPDATES, {
        variables: {
            campaignId: campaignId,
            type: 'progress',
        },
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setUpdates(data.findOwnFundraiserUpdates)
    });

    return (
       <div className="p-1 sm:p-2 space-y-3">
            <GlassCard width="max-w-2xl space-y-3">
                <OnLoadViewer loading={loading || campaignLoading || configLoading} error={error ?? campaignError ?? configError ?? queryError}>
                    { config && campaign && fundraiser &&
                    <>
                        <GroupBox>
                            <h1 className="text-3xl leading-normal sm:text-5xl sm:leading-normal font-bold mx-auto text-center text-fgCard-primary">
                                Congratulations - your fundraiser is now live!
                            </h1>
                        </GroupBox>

                        <GroupBox>
                            <div className="mx-auto max-w-4xl w-full">
                                { fundraiser && <CampaignProgressBar total={fundraiser.total} target={fundraiser.target} supporters={fundraiser.uniqueDonors} /> }
                            </div>
                            { campaign && <div className="text-sm text-fgCard-secondary">Campaign {campaign.hasEnded ? 'ended' : 'ends'} on {dateToStringNoTime(campaign.endDate)}</div> }
                        </GroupBox>

                        <GroupBox className="space-y-3">
                            <div className="text-lg font-bold">{fundraiser.name}</div>
                            <div className="text-sm">{fundraiser.fullDescription}</div>
                            <button className={buttonStyles.primaryLg} onClick={() => navigate(`/fundraiser/${campaign._id}/edit`)}>
                                Change fundraiser details
                            </button>
                        </GroupBox>

                        <GroupBox>
                            <div className="flex flex-row items-center gap-2">
                                <SendOutlinedIcon fontSize="large" className="text-fgCard-primary" />
                                <h2 className="text-sm sm:text-base leading-relaxed">Share your goal with friends and colleagues...</h2>
                            </div>
                            <ShareOrCopyButton
                                action={ACTIONS.shareFundraiser}
                                details={fundraiser ? {
                                    title: `Help me raise funds for ${campaign?.charityId.name}`,
                                    text: `I'm raising money in aid of "${campaign?.name}" through Giving Is Human.`,
                                    url: fundraiser.dynamicLink,
                                } : null}
                            />
                        </GroupBox>

                        <InAidOfSummaryWidget config={config} campaign={campaign} />

                        <GroupBox>
                            { thanks ?
                            <div className="flex flex-col gap-3">
                                <h2 className="text-lg font-bold">Well done for adding a video message thanking your supporters - they will feel more valued!</h2>
                                <UpdatePreview config={config} update={thanks} onClick={() => setCurrentUpdate(thanks)} />
                            </div>
                            :
                            <div className="flex flex-col gap-3">
                                <div className="flex flex-row items-center gap-2">
                                    <LightbulbOutlinedIcon fontSize="large" className="text-fgCard-primary" />
                                    <h2 className="text-sm sm:text-base leading-relaxed">Make your donors feel appreciated by adding a video message of thanks that'll be shown to everyone who supports your fundraising drive.</h2>
                                </div>
                                <button className={buttonStyles.primaryLg} onClick={() => setCurrentUpdate(modelUpdate(fundraiser, 'thanks'))}>
                                    Add a thank you video
                                </button>
                            </div>
                            }
                        </GroupBox>
                    </>
                    }
                </OnLoadViewer>
            </GlassCard>

            { fundraiser && 
            <GlassCard width="max-w-2xl space-y-3">
                <OnLoadViewer loading={loadingThanks || loadingUpdates} error={thanksError ?? updatesError}>
                    { updates?.length < 1 &&
                    <GroupBox>
                        <div className="flex flex-row items-center gap-2">
                            <CampaignOutlinedIcon fontSize="large" className="text-fgCard-primary" />
                            <h2 className="text-sm sm:text-base leading-relaxed">Just reached a milestone? Something important to say? Don't forget to keep your followers up-to-date with the progress of your campaign!</h2>
                        </div>
                        <button className={buttonStyles.primaryLg} onClick={() => setCurrentUpdate(modelUpdate(fundraiser, 'progress'))}>
                            Add a progress update
                        </button>
                    </GroupBox>
                    }

                    { updates?.length > 0 &&
                    <GroupBox>
                        <button className={buttonStyles.emeraldLg} onClick={() => setCurrentUpdate(modelUpdate(fundraiser, 'progress'))}>
                            Add a progress update
                        </button>
                    </GroupBox>
                    }

                    { updates.map(update => (
                    <GroupBox key={update._id}>
                        <div className="text-sm italic">{dateToStringRelative(update.createdAt)}</div>
                        <UpdatePreview config={config} update={update} onClick={() => setCurrentUpdate(update)} />
                    </GroupBox>
                    ))}
                </OnLoadViewer>
            </GlassCard>
            }

            { currentUpdate &&
            <CreateOrEditFundraiserUpdate
                config={config}
                initial={currentUpdate}
                onComplete={() => setCurrentUpdate(null)}
            />
            }
        </div>
    );
}

function UpdatePreview({config, update, onClick}) {

    const [deleteUpdate] = useMutation(DELETE_FUNDRAISER_UPDATE, { update: evictAllDependentOnCampaignUpdates });

    return (
        <div className="flex flex-col sm:flex-row gap-3 mx-auto">
            <div className="mx-auto">
                <ImageVCentre
                    rmtPrefix={config.imageURLPrefix}
                    rmt={update.imageName ?? update.videoName}
                    what={update.imageName ? "thumbnail" : "videoThumbnail"}
                    NotFoundPlaceholder={update.videoName ? ImagePlaceholder : BrokenMediaPlaceholder}
                    notFoundSeverity={update.videoName ? "info" : "error"}
                    notFoundText={update.videoName ? "Generating preview image..." : null}
                />
            </div>
            <div className="flex flex-col gap-3 w-full">
                <h2 className="text-lg">{update.heading}</h2>
                <div className="text-sm">{update.text}</div>
                <div className="flex flex-row mx-auto items-center gap-2">
                    <button className={buttonStyles.primaryLgNarrow} onClick={onClick}>
                        {update.type === 'thanks' ? 'Change my thank you message' : 'Edit update'}
                    </button>
                    <button className={buttonStyles.redLgNarrow} onClick={() => deleteUpdate({ variables: { id: update._id }})}>
                        Delete
                    </button>
                </div>
            </div>
        </div>
    );
}
