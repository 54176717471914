import React from "react";

import {GroupBox, ImageVCentre, monetaryAmountToString, CharitySummaryMini} from "gih_web_common";


export function TotalAmountWidget({ donationAmount, voluntaryAmount }) {

    const totalAmount = donationAmount + voluntaryAmount;

    return (
        <GroupBox>
            <div className="flex justify-between items-center">
                <span className="text-lg font-semibold text-fgCard-secondary">Donation</span>
                <span className="text-lg font-semibold text-fgCard-secondary">{monetaryAmountToString(donationAmount, true)}</span>
            </div>
            <div className="flex justify-between items-center">
                <span className="text-lg font-semibold text-fgCard-secondary">Voluntary contribution</span>
                <span className="text-lg font-semibold text-fgCard-secondary">{monetaryAmountToString(voluntaryAmount, true)}</span>
            </div>
            <div className="flex justify-between items-center">
                <span className="text-lg font-semibold text-fgCard-secondary">Total</span>
                <span className="text-3xl font-semibold text-fgCard-primary">{monetaryAmountToString(totalAmount, true)}</span>
            </div>
        </GroupBox>
    );
}

export function CampaignSummaryWidget({campaign, config}) {
    return (
        <div className="grid lg:grid-cols-2 gap-2">
            <GroupBox>
                <div className="flex flex-row gap-3 justify-between items-center">
                    <h1 className="text-xl font-bold w-[50%]">{campaign.name}</h1>
                    <ImageVCentre rmtPrefix={config.imageURLPrefix} rmt={campaign.imageURL} what="primaryImageSmall" />
                </div>
            </GroupBox>

            <GroupBox>
                <div className="flex flex-row gap-3 justify-between items-center">
                    <div className="max-w-[70%]">
                        <div className="font-semibold text-lg">
                            {campaign.charityId.name}
                        </div>
                        { campaign.charityId.registration &&
                        <div className="text-sm text-fgCard-secondary space-x-1">
                            <span className="font-semibold">Registered charity number:</span>
                            <span>{campaign.charityId.registration}</span>
                        </div>
                        }
                    </div>
                    <ImageVCentre rmtPrefix={config.imageURLPrefix} rmt={campaign.charityId.logoPicture} what="logoSmall" />
                </div>
            </GroupBox>
        </div>
    )
}

export function InAidOfSummaryWidget({config, campaign}) {
    return (
        <GroupBox>
            <h2 className="text-sm sm:text-base leading-relaxed">All proceeds will go to the campaign <strong>"{campaign.name}"</strong></h2>
            <CharitySummaryMini config={config} charity={campaign.charityId} />
        </GroupBox>
    );
}

export function SectionHeader({children}) {
    return <div className="bg-primary-dark text-white text-2xl font-bold py-2 px-4">{children}</div>
}

