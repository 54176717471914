import {useState, useEffect} from "react";
import {useParams, useNavigate} from 'react-router-dom';

import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';

import {useQuery, useMutation} from '@apollo/client';

import {
    OnLoadViewer,
    GlassCard,
    GroupBox,
    FormWrapper,
    FormInput,
    FormTextarea,
    FormStickyPrimaryButton,
    formError,
    VideoPosterPreview,
    buttonStyles,
    ImageVCentre,
    TagInput,
    Modal,
    InputLabelledMonetaryAmount,
    monetaryAmountToString,
    WebLink,
} from 'gih_web_common';

import {logScreenViewEvent, logActionSuccess, logActionGraphQLFailure, logFormValidationFailure, logFileSuccess, logFileFailure} from "../../utils/analytics";
import {SCREEN_NAME, SCREEN_CLASS, FILES} from "../../utils/analyticsConstants";
import { GET_OWN_FUNDRAISER, CREATE_OWN_FUNDRAISER, UPDATE_OWN_FUNDRAISER, evictAllDependentOnFundraisers } from '../../utils/graphQL/personal';
import { GET_CONFIG, GET_CAMPAIGN_BY_ID } from '../../utils/graphQL/queries';
import { upload } from '../../utils/firebase';

import { InAidOfSummaryWidget } from '../../common/donationWidgets';
import { VideoFileSelector, PhotoFileSelector } from '../../common/mediaPicker';


function getFormValues(existing) {
    return {
        name: existing?.name ?? '',
        fullDescription: existing?.fullDescription ?? '',
    };
}

function marshalCampaign(detail) {
    return {
        ...detail.campaign,
        endDate: new Date(detail.campaign.endDate),
        status: detail.status,
    };
}

export default function MyFundraiserPage() {

    const {campaignId} = useParams();
    const navigate = useNavigate();

    const [formValues, setFormValues] = useState(getFormValues(null));
    const [targetAmount, setTargetAmount] = useState(null);
    const [keywords, setKeywords] = useState([]);
    const [modified, setModified] = useState(false);
    const [confirm, setConfirm] = useState(false);

    const [imageFile, setImageFile] = useState(null);
    const [imageLocalURL, setImageLocalURL] = useState(null);

    const [videoFile, setVideoFile] = useState(null);
    const [videoLocalURL, setVideoLocalURL] = useState(null);
    const [videoPosterTime, setVideoPosterTime] = useState(null);

    const { loading: configLoading, data: configData, error: configError } = useQuery(GET_CONFIG);
    const config = configData?.getConfig;

    const { loading: campaignLoading, error: campaignError, data: campaignData } = useQuery(GET_CAMPAIGN_BY_ID, {
        variables: {
            id: campaignId
        }});
    const campaign = campaignData?.findCampaignById ? marshalCampaign(campaignData.findCampaignById) : null;
    const maxTargetGBP = campaign ? campaign.target/100 : 0;

    function setValues(fundraiser) {
        console.log(fundraiser);
        setVideoPosterTime(fundraiser?.videoPosterTime ?? null);
        setKeywords(fundraiser?.keywords ?? []);
        setTargetAmount(fundraiser.target);
        setModified(false);
    }

    const {loading, error, data} = useQuery(GET_OWN_FUNDRAISER, {
        variables: {
            campaignId: campaignId,
        },
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setValues(data.findOwnFundraiserByCampaignId)
    });
    const existing = data?.findOwnFundraiserByCampaignId;

    const [createFundraiser] = useMutation(CREATE_OWN_FUNDRAISER);
    const [updateFundraiser] = useMutation(UPDATE_OWN_FUNDRAISER);

    function onSubmit() {
        return new Promise((resolve, reject) => {
            try {
                const fundraiser = getValues();
                setConfirm({
                    ok: () => {
                        setConfirm(null);
                        resolve(apply(fundraiser));
                    },
                    cancel: () => {
                        setConfirm(null);
                        reject(formError("read our fundraising guidelines, make any necessary changes"));
                    }
                });
            } catch (e) {
                reject(e);
            }
        });
    }
    
    async function apply(fundraiser) {

        await upload(imageFile, 'fundraiser', 'header', FILES.fundraiserImage, fullPath => { fundraiser.imageURL = fullPath; });
        await upload(videoFile, 'fundraiser', 'video', FILES.fundraiserVideo, fullPath => { fundraiser.videoURL = fullPath; });

        return (existing ? updateFundraiser : createFundraiser)({
            variables: {
                fundraiser: fundraiser
            },
            update: evictAllDependentOnFundraisers,
        })
        .then(() => navigate(`/fundraiser/${campaign._id}/overview`));
    }

    function getValues() {

        const fundraiser = {
            ...formValues,
            keywords,
            imageURL: existing?.imageURL ?? null,
            videoURL: existing?.videoURL ?? null,
            hidden: false,
            target: targetAmount,
            inAidOf: campaign._id,
        };

        if (fundraiser.target < config.fundraiser.minimumTargetGBP*100 || fundraiser.target > maxTargetGBP*100)
            throw formError(`choose a target amount between ${monetaryAmountToString(config.fundraiser.minimumTargetGBP*100)} up to the full campaign target of ${monetaryAmountToString(maxTargetGBP*100)}`);

        if (fundraiser.keywords.length < 1)
            throw formError("enter one or more keywords that describe your fundraiser");

        if (!existing?.imageURL && !imageFile)
            throw formError("upload a picture for the campaign");

        return fundraiser;
    }

    function onSelectVideo(file) {
        try {
            setVideoPosterTime(null);
            setVideoFile(file);
            setVideoLocalURL(URL.createObjectURL(file));
            logFileSuccess(FILES.fundraiserVideo, file);
        } catch (e) {
            logFileFailure(FILES.fundraiserVideo, e);
        }
    }

    function onSelectImage(file) {
        try {
            setImageFile(file);
            setImageLocalURL(URL.createObjectURL(file));
            logFileSuccess(FILES.fundraiserImage, file);
        } catch (e) {
            logFileFailure(FILES.fundraiserImage, e);
        }
    }

    useEffect(() => {
        logScreenViewEvent(SCREEN_NAME.fundraiser, SCREEN_CLASS.user);
    }, []);
//"flex flex-row items-center text-fgCard-primary hover:bg-primary-darker hover:text-white rounded-full"
    return (
       <div className="p-1 sm:p-2">
            <GlassCard width="max-w-2xl space-y-3">
                <OnLoadViewer loading={loading || campaignLoading || configLoading} error={error ?? campaignError ?? configError}>
                    <div className="flex flex-col sm:flex-row sm:items-center gap-3">
                        { existing &&
                        <div className="w-fit">
                        <button className={buttonStyles.outlineLgNarrow} onClick={() => navigate(`/fundraiser/${campaign._id}/overview`)}>
                            <ArrowCircleLeftOutlinedIcon fontSize="large" />
                            <span className="ml-1">Back to summary</span>
                        </button>
                        </div>
                        }
                        <h2 className="text-2xl sm:text-4xl font-bold text-fgCard-primary">{existing ? "Make changes to your fundraiser" : "Start your own fundraiser"}</h2>
                    </div>

                    { config && campaign &&
                    <InAidOfSummaryWidget config={config} campaign={campaign} />
                    }

                    { config &&
                    <FormWrapper
                        initialValues={getFormValues(existing)}
                        setModified={setModified}
                        onSubmit={onSubmit}
                        onValueChange={setFormValues}
                        onValidationFailure={logFormValidationFailure}
                        className="space-y-3"
                    >
                        <GroupBox>
                            <FormInput
                                id="name"
                                name="name"
                                length={formValues.name.length}
                                maxLength={config.campaign.nameMaxLength}
                                type="text"
                                placeholder="title"
                                label="Your fundraising headline"
                                required
                                rootClassName="w-full"
                            />
                            <FormTextarea
                                rows={5}
                                id="fullDescription"
                                name="fullDescription"
                                length={formValues.fullDescription.length}
                                maxLength = {config.campaign.descriptionMaxLength}
                                type="text"
                                placeholder="description"
                                label="Description"
                                required
                                rootClassName="w-full"
                            />
                            <InputLabelledMonetaryAmount
                                id="targetAmount"
                                placeholder="Your target"
                                amount={targetAmount}
                                setAmount={setTargetAmount}
                                maxGBP={maxTargetGBP}
                                minGBP={config.fundraiser.minimumTargetGBP}
                                label={`Your fundraising target (anything from ${monetaryAmountToString(config.fundraiser.minimumTargetGBP*100)} up to the full campaign target of ${monetaryAmountToString(maxTargetGBP*100)}.`}
                                subText="Don't worry if you don't reach your target - everything you raise will still go to your chosen cause."
                            />
                            <TagInput
                                id="keywords"
                                label="Keywords"
                                placeholder="Enter a keyword and press return"
                                value={keywords}
                                setValue={setKeywords}
                                findMatches={hint => [hint]}
                                settings={{
                                    maxTags: config.campaign.keywordsMax,
                                }}
                            />
                        </GroupBox>

                        <GroupBox>
                            <PhotoFileSelector editing={existing?.imageURL || imageLocalURL} onSelectFile={onSelectImage} />
                            <div className="mx-auto">
                                <ImageVCentre
                                    rmtPrefix={config.imageURLPrefix}
                                    rmt={existing?.imageURL}
                                    local={imageLocalURL}
                                    what="imagePreview"
                                    placeholderSeverity="info"
                                    placeholderText="Cover photo."
                                />
                            </div>
                        </GroupBox>

                        <GroupBox>
                            <VideoFileSelector editing={existing?.videoURL || videoLocalURL} onSelectFile={onSelectVideo} />
                            <VideoPosterPreview
                                rmtPrefix={config.imageURLPrefix}
                                rmt={existing?.videoURL}
                                local={videoLocalURL}
                                posterTime={videoPosterTime}
                                setPosterTime={setVideoPosterTime}
                                what="imagePreview"
                                placeholderSeverity="info"
                                placeholderText="It's optional, but we strongly recommend adding a video - up to 30s duration - you'll make a stronger impact when you share your fundraiser!"
                            />
                        </GroupBox>

                        <FormStickyPrimaryButton disabled={!modified} rounded>
                            {existing ? "Save changes" : "Create fundraiser"}
                        </FormStickyPrimaryButton>
                    </FormWrapper>
                    }
                </OnLoadViewer>
            </GlassCard>
            
            { confirm &&
            <Modal
                title={null}
                onDismiss={() => confirm.cancel()}
                footer={
                    <div className="flex grid grid-cols-2 w-full gap-2">
                        <button className={buttonStyles.primaryLg} type="button" onClick={() => confirm.ok()}>
                            OK
                        </button>
                        <button className={buttonStyles.redLg} type="button" onClick={() => confirm.cancel()}>
                            Cancel
                        </button>
                    </div>
                }
            >
                <h1 className="text-base sm:text-lg font-semibold">
                    By clicking "OK" below you agree that you have read our advice regarding fundraising and accept
                    the Giving Is Human Terms & Conditions.
                </h1>
                <div className="text-sm sm:text-base">
                    In particular, we recommend that you read the Fundraising Regulator's
                    advice <WebLink href="https://www.fundraisingregulator.org.uk/code/all-fundraising/behaviour-when-fundraising">here</WebLink> that
                    covers <strong>your behaviour</strong> when fundraising <strong>in aid of</strong> a non-profit organisation.
                </div>
            </Modal>
            }
        </div>
    );
}
