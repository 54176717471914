import {useEffect, useState, useRef} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";

import {useLazyQuery, useMutation} from '@apollo/client';
import {onAuthStateChanged, signOut} from 'firebase/auth';

import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import NearMeIcon from '@mui/icons-material/NearMe';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import PersonIcon from '@mui/icons-material/Person';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

import {GenericNavigationBar, InactivityTimer, graphQLErrorAsText} from 'gih_web_common';
import logoImage from 'gih_web_common/assets/gih-logo-1818x268.png';

import {auth} from "../utils/firebase";
import {GET_APP_USER_BY_FIREBASEID, APP_ATTACH_DONATION_TO_USER} from "../utils/graphQL/personal";
import {logActionSuccess} from "../utils/analytics";
import {ACTIONS} from "../utils/analyticsConstants";

export function getFundraiserButtonPath(campaign) {
    if (campaign.fundraiserId) {
        return `/fundraiser/${campaign.fundraiserId}/overview`;
    } else if (campaign.inAidOf) {
        return `/fundraiser/${campaign.inAidOf._id}/create`;
    } else {
        return `/fundraiser/${campaign._id}/create`;
    }
}

function getNavLinks(loginState) {

    if (process.env.REACT_APP_ALLOW_LOGIN !== 'true')
        return [];

    switch (loginState) {
        case 'LoggedIn':
            return [
                { to: '/discover', label: 'Discover', icon: LightbulbOutlinedIcon },
                { to: '/search', label: 'Search', icon: NearMeIcon },
                { to: '/myCauses', label: 'My causes', icon: FavoriteOutlinedIcon },
                { to: '/myDonations', label: 'My donations', icon: AccountBalanceOutlinedIcon },
                { to: '/myProfile', label: 'My profile', icon: PersonIcon },
                { to: '/sign-out', label: 'Logout', icon: LogoutOutlinedIcon },
            ];

        case 'LoggedOut':
            return [
                { to: '/discover', label: 'Discover', icon: LightbulbOutlinedIcon },
                { to: '/search', label: 'Search', icon: NearMeIcon },
                { to: '/sign-in', label: 'Sign in', icon: PersonIcon },
            ];

        default:
            return [];
    }
}

export default function NavigationBar() {

    const navigate = useNavigate();
    const location = useLocation();

    const [inactivityTimeout, setInactivityTimeout] = useState(false);
    const inactivityTimer = useRef(null);

    const dispatch = useDispatch();
    const loginState = useSelector(state => state.loginState);
    const lastDonation = useSelector(state => state.lastDonation);

    const [lastLoginState, setLastLoginState] = useState(null);

    const [attachDonation] = useMutation(APP_ATTACH_DONATION_TO_USER);

    const [requestUser] = useLazyQuery(GET_APP_USER_BY_FIREBASEID, {
        fetchPolicy: "no-cache",
        onCompleted: data => {
            if (loginState === 'SignupAuth' || loginState === 'SigninAuth') {

                inactivityTimer.current?.stop();
                const user = data.findUserFirebaseId;

                if (user) {
                    console.log('Received USER response');

                    if (lastDonation) {
                        console.log('Attempting to attach last donation to user');
                        attachDonation({
                            variables: {
                                paymentIntent: lastDonation.paymentIntent
                            }})
                            .then(r => {
                                console.log(`Result of attaching last donation to user was: ${r.data.appAttachDonationToUser}`);
                            });
                    }

                    dispatch({type: "SET_USER", payload: user});

                    /*inactivityTimer.current = new InactivityTimer({
                        gracePeriod: 1800, // Expire after 30 minutes
                        onExpiry: () => setInactivityTimeout(true),
                    });*/

                } else if (loginState === 'SignupAuth') {
                    console.log('No existing user matching authenticated user id');
                    dispatch({ type: 'SIGNUP_GATHER_USER_DETAILS', payload: null });

                } else {
                    console.log('No user matching authenticated user id - logging out');
                    signOut(auth);
                }
            }
        },
        onError: e => {
            console.log(`Failed to get user: ${graphQLErrorAsText(e)}`);
            signOut(auth);
        },
    });

    useEffect(() => {
        onAuthStateChanged(auth, async authUser => {
            if (process.env.REACT_APP_ALLOW_LOGIN !== 'true') {
                console.log('Signin disabled');
                dispatch({ type: "CLEAR_AUTH" });

            } else if (authUser) {
                logActionSuccess(ACTIONS.userAuthenticate, `for Firebase user ${authUser.uid}`);
                dispatch({type: "SET_AUTH", payload: authUser});
                requestUser({ variables: { firebaseId: authUser.uid }});

            } else {
                console.log('Not logged in');
                dispatch({ type: "CLEAR_AUTH" });
            }
        })

    }, [dispatch, requestUser]);

    useEffect(() => {
        if (inactivityTimeout) {
            logActionSuccess(ACTIONS.userInactivityLogout, "Inactivity timer expired");
            signOut(auth).then(() => {
                setInactivityTimeout(false);
                inactivityTimer.current?.stop();
                inactivityTimer.current = null;
            });
        }
    }, [inactivityTimeout])

    useEffect(() => {
        if (loginState !== lastLoginState) {
            console.log('loginState', lastLoginState, '->', loginState);
            if (loginState === 'ReqTsAndCs' || loginState === 'SignupGatherUserDetails') {
                navigate('/myProfile', { state: location.state, replace: true });
            } else if (loginState === 'LoggedIn' && (lastLoginState === 'SignupAuth' || lastLoginState === 'SignupGatherUserDetails')) {
                navigate(location.state?.target ?? '/myCauses', { replace: true });
            }
            setLastLoginState(loginState);
        }
    }, [loginState, lastLoginState, navigate]);

    return (
        <GenericNavigationBar getLinks={() => getNavLinks(loginState)}>
            <img src={logoImage} alt="Giving Is Human logo" style={{ height: "46px" }}/>
        </GenericNavigationBar>
    );
}

