import {useState, useEffect, Fragment} from "react";
import {useNavigate} from 'react-router-dom';

import {useQuery, useMutation} from "@apollo/client";

import {OnLoadViewer, graphQLErrorAsText, CampaignCard} from "gih_web_common";

import {logScreenViewEvent} from "../../utils/analytics";
import {SCREEN_NAME, SCREEN_CLASS} from "../../utils/analyticsConstants";
import {GET_CAMPAIGN_BY_SCORE, GET_CONFIG, INTERACTION_EVENT} from "../../utils/graphQL/queries";

import {LegalLinks, LegalModal} from "../../common/legal";
import {SectionHeader} from "../../common/donationWidgets";

import {CampaignModal} from "./modal";


export default function CausesRanked() {

    const navigate = useNavigate();

    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [modal, setModal] = useState(null);

    const { loading: loadingConfig, data: configData, error: configError } = useQuery(GET_CONFIG, {
        onError: e => console.error(graphQLErrorAsText(e))
    });

    const {loading: loadingCampaigns, data: campaignData, error: campaignsError} = useQuery(GET_CAMPAIGN_BY_SCORE, {
        onError: e => console.error(graphQLErrorAsText(e))
    });

    const config = configData?.getConfig;
    const categories = campaignData?.getFilteredCampaignsByScore;

    const [interactionEvent] = useMutation(INTERACTION_EVENT);

    function onOpenModal(campaign) {
        setSelectedCampaign(campaign);
        interactionEvent({ variables: {
            context: 'campaignCard',
            what: 'findOutMore',
            campaignId: campaign._id,
        }});
    }

    useEffect(() => {
        logScreenViewEvent(SCREEN_NAME.campaignDiscover, SCREEN_CLASS.campaign);
    }, []);

    return (
        <>
            <div className="flex flex-col space-y-4">
                <OnLoadViewer loading={loadingConfig || loadingCampaigns} error={campaignsError ?? configError}>
                    { categories && [{
                        campaigns: categories.spotlight,
                        heading: 'In the spotlight...'
                    }, {
                        campaigns: categories.scored,
                        heading: 'Discover more'
                    }].filter(item => item.campaigns.length > 0).map((item, index) => (
                    <Fragment key={index}>
                        <SectionHeader>{item.heading}</SectionHeader>
                        <div className="flex flex-wrap gap-2 p-1 sm:p-4 w-fit mx-auto">
                            { item.campaigns.map(campaign => (
                            <CampaignCard
                                key={campaign._id}
                                campaign={campaign}
                                config={config}
                                onOpenModal={() => onOpenModal(campaign)}
                                onDonate={() => navigate(`/campaign/${campaign._id}`)}
                            />
                            ))}
                        </div>
                    </Fragment>
                    ))}
                </OnLoadViewer>

                { categories &&
                <LegalLinks setModal={setModal} individual={true}/>
                }
            </div>

            { selectedCampaign &&
            <CampaignModal
                campaign={selectedCampaign}
                config={config}
                onCloseModal={() => setSelectedCampaign(null)}
                onDonate={() => {
                    window.open(selectedCampaign.dynamicLink, '_blank');
                    setSelectedCampaign(null);
                }}
            />
            }

            { modal &&
            <LegalModal modal={modal} setModal={setModal}/>
            }
        </>
    );
}
