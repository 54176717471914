import {gql} from "@apollo/client";

import {campaignDetail} from "./common";

export function evictAllDependentOnCampaignUpdates(cache, {data}) {
    cache.evict({
        id: "ROOT_QUERY", 
        fieldName: "findOwnFundraiserUpdates",
    })
    console.log(`Evicting query results dependent on fundraiser updates`);
    cache.gc();
}

export function evictAllDependentOnFundraisers(cache, {data}) {
    [
        "findOwnFundraiserByCampaignId",
        "findOwnFundraisers",
    ].forEach(query => cache.evict({
        id: "ROOT_QUERY", 
        fieldName: query,
    }));
    console.log(`Evicting query results dependent on own fundraisers`);
    cache.gc();
}

const appUser = `
    _id
    title
    firstName
    lastName
    firebaseId
    profilePicture
    createdAt
    name
    age
    gender {
        male
        female
        nonBinary
        transgender
        intersex
        preferNotToSay
    }
    phone
    postcode
    address {
        line1
        line2
        streetName
        townCity
        postcode
    }
    interests
    acceptedTsAndCs
    badgedChangesWatermark
    deletionRequestedAt
    downloadTokens {
        value
        name
    }
`;

export const GET_APP_USER_BY_FIREBASEID = gql`
    query FindUserFirebaseId($firebaseId: String!) {
        findUserFirebaseId(firebaseId: $firebaseId) {
            ${appUser}
        }
    }
`;

export const APP_ATTACH_DONATION_TO_USER = gql`
    mutation AppAttachDonationToUser($paymentIntent: String!) {
        appAttachDonationToUser(paymentIntent: $paymentIntent)
    }
`;

export const APP_UPDATE_USER_DETAILS = gql`
    mutation AppUpdateUserDetails($firebaseId: String!, $details: AppUserDetailsInput!) {
        appUpdateUserDetails(firebaseId: $firebaseId, details: $details) {
            ${appUser}
        }
    }
`;

export const APP_ADD_USER = gql`
    mutation AppAddUser($firebaseId: String!, $details: AppUserDetailsInput!, $paymentIntent: String) {
        appAddUser(firebaseId: $firebaseId, details: $details, paymentIntent: $paymentIntent) {
            ${appUser}
        }
    }
`;

export const GET_MY_FUNDRAISERS = gql`
    query findOwnFundraisers {
        findOwnFundraisers {
            ${campaignDetail}
            inAidOf {
                ${campaignDetail}
            }
        }
    }
`;

export const GET_MY_CAUSES = gql`
    query FindMyCampaignsByUserId($userId: ID!) {
        findMyCampaignsByUserId(userId: $userId) {
            _id
            total
            giftAidAmount
            count
            latest
            lastSeenUpdate
            campaign {
                ${campaignDetail}
            }
            updates {
                _id
                createdAt
                type
                postedBy
                heading
                videoName
                imageName
                text
            }
            subscription {
                _id
                termination
                status
                paymentDay
                paused
                giftAid
                donation
                tip
            }
        }
    }
`;

export const GET_MY_DONATIONS = gql`
    query FindDonationsByUserId($userId: ID!, $taxYear: Int!) {
        findDonationsByUserId(userId: $userId, taxYear: $taxYear) {
            _id
            amount
            giftAidAmount
            when
            status
            comment
            campaign {
                _id
                type
                name
                imageURL
                videoURL
                charityId {
                    _id
                    name
                    logoPicture
                }
            }
        }
    }
`;

const fundraiser = `
    _id
    name
    fullDescription
    keywords
    imageURL
    videoURL
    videoPosterTime
    dynamicLink
    hidden
    createdAt
    uniqueDonors
    total
    target
`;

export const GET_OWN_FUNDRAISER = gql`
    query FindOwnFundraiserByCampaignId($campaignId: ID!) {
        findOwnFundraiserByCampaignId(campaignId: $campaignId) {
            ${fundraiser}
        }
    }
`;

export const CREATE_OWN_FUNDRAISER = gql`
    mutation CreateOwnFundraiser($fundraiser: FundraiserInput!) {
        createOwnFundraiser(fundraiser: $fundraiser) {
            ${fundraiser}
        }
    }
`;

export const UPDATE_OWN_FUNDRAISER = gql`
    mutation UpdateOwnFundraiser($fundraiser: FundraiserInput!) {
        updateOwnFundraiser(fundraiser: $fundraiser) {
            ${fundraiser}
        }
    }
`;

const campaignUpdate = `
    _id
    createdAt
    type
    postedBy
    heading
    videoName
    imageName
    text
    threshold
`;

export const GET_OWN_FUNDRAISER_UPDATES = gql`
    query FindOwnFundraiserUpdates($campaignId: ID!, $type: String!) {
        findOwnFundraiserUpdates(campaignId: $campaignId, type: $type) {
            ${campaignUpdate}
        }
    }
`;

export const ADD_FUNDRAISER_UPDATE = gql`
    mutation AddCampaignUpdate($campaignId: ID!, $update: CampaignUpdateInput!) {
        addCampaignUpdate(campaignId: $campaignId, update: $update) {
            ${campaignUpdate}
        }
    }
`;

export const UPDATE_FUNDRAISER_UPDATE = gql`
    mutation UpdateCampaignUpdate($id: ID!, $update: CampaignUpdateInput!) {
        updateCampaignUpdate(id: $id, update: $update) {
            ${campaignUpdate}
        }
    }
`;

export const DELETE_FUNDRAISER_UPDATE = gql`
    mutation DeleteCampaignUpdate($id: ID!) {
        deleteCampaignUpdate(id: $id)
    }
`;
