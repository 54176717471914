import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import {
    GlassPreviewCard,
    GroupBox,
    CharitySummaryMini,
    monetaryAmountToString,
    dateToString,
} from "gih_web_common";


export function MyDonationCard({ myDonation, config }) {

    const campaign = myDonation.campaign;

    return (
        <GlassPreviewCard
            rmtPrefix={config.imageURLPrefix}
            rmt={campaign.imageURL}
            height={500}
        >
            <GroupBox translucent={true}>
                <span className="text-lg font-bold">{campaign.name}</span>
            </GroupBox>
            <GroupBox>
                <CharitySummaryMini config={config} charity={campaign.charityId} />
            </GroupBox>
            <GroupBox>
                <div className="flex space-x-2 items-center">
                    <CalendarMonthIcon />
                    <span className="text-sm">{dateToString(myDonation.when)}</span>
                </div>
            </GroupBox>
            <GroupBox>
                <div className="flex space-x-2 items-center text-fgCard-primary">
                    <ThumbUpOutlinedIcon />
                    <div className="flex flex-col space-y-1 text-sm font-bold">
                        <span>You gave {monetaryAmountToString(myDonation.amount)}</span>
                        { myDonation.giftAidAmount && <span>+{monetaryAmountToString(myDonation.giftAidAmount)} GiftAid</span> }
                    </div>
                </div>
            </GroupBox>
        </GlassPreviewCard>
    );
}
