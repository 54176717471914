export const campaignDetail = `
    _id
    address {
        line1
        line2
        streetName
        townCity
        postcode
    }
    places {
        name
        qualifier
    }
    charityId {
        _id
        name
        profilePicture
        logoPicture
        fullDescription
        briefDescription
        registration
        address {
            line1
            line2
            streetName
            townCity
            postcode
        }
    }
    inAidOf {
        _id
        name
        fullDescription
        imageURL
    }
    donations {
        uniqueDonors
        total
        recent {
            _id
            name
            amount
            giftAidAmount
            when
            comment
        }
    }
    name
    fullDescription
    createdBy
    imageURL
    videoURL
    videoPosterTime
    target
    type
    dynamicLink
    endDate
    allowGiftAid
    lastNotifiableUpdate
`;
