import {useState, useEffect} from "react";
import {useDispatch} from "react-redux";

import {browserSessionPersistence, setPersistence, indexedDBLocalPersistence, signInWithPhoneNumber, signInWithEmailAndPassword, RecaptchaVerifier} from "firebase/auth";

import {inputStyles, FormWrapper, FormInput, FormErrorText, FormPrimaryButton, FormCheckBox, authErrorDecoder, canonicalisePhoneNumber} from 'gih_web_common';

import {auth} from "../utils/firebase";
import {logActionSuccess, logActionFailed, logActionUserError, logFormValidationFailure} from "../utils/analytics";
import {ACTIONS} from "../utils/analyticsConstants";


export function SignupWidget({intro}) {

    const dispatch = useDispatch();

    const [state, setState] = useState(null);

    useEffect(() => {
        return () => {
            console.log('Signup cleanup');
            window.recaptchaVerifier = null;
        }
    }, []);

    // E-mail and password sign-in.
    function signIn(values) {

        return new Promise((resolve, reject) => {
            const persistence = values.remember_me ? indexedDBLocalPersistence : browserSessionPersistence;
            const email = values.email.trim().toLowerCase();
            setPersistence(auth, persistence)
                .then(() => {
                    signInWithEmailAndPassword(auth, email, values.password.toString())
                        .then(() => {
                            logActionSuccess(ACTIONS.userLogin, `for ${email}`);
                            // Automatic Redirect to HomeIndex
                            resolve();
                        }, e => {
                            logActionUserError(ACTIONS.userLogin, `for ${email} with code ${e.code}`);
                            reject(authErrorDecoder(e));
                        });
                });
        });
    }

    // Mobile number and OTP sign-in.
    function onSubmit(values) {

        return new Promise((resolve, reject) => {

            if (state) {
                state.result.confirm(values.otp.trim())
                    .then(r => {
                        logActionSuccess(ACTIONS.userSignup, `for ${state.phone}`);
                        // Automatic Redirect to myProfile.
                        resolve();
                    }, e => {
                        logActionFailed(ACTIONS.userSignup, `for ${state.phone}: ${e}`);
                        reject({ otp: 'Please check the code' });
                    });

            } else {
                const persistence = values.remember_me ? indexedDBLocalPersistence : browserSessionPersistence;
                const phone = canonicalisePhoneNumber(values.phone);

                console.log(`Phone number is ${phone}`);

                if (!window.recaptchaVerifier) {
                    console.log('Making recapture verifier');
                    window.recaptchaVerifier = new RecaptchaVerifier(auth, 'gih_recaptchaContainer', {
                        size: 'normal',
                        callback: r => {
                            // reCAPTCHA solved, allow signInWithPhoneNumber.
                            console.log(`ReCaptcha callback`); 
                        },
                        'expired-callback': () => {
                            // Response expired. Ask user to solve reCAPTCHA again.
                            console.log('ReCaptcha expired');
                        }
                    });
                }

                dispatch({ type: 'SIGNUP_BEGIN', payload: null });

                setPersistence(auth, persistence)
                    .then(() => {
                        signInWithPhoneNumber(auth, phone, window.recaptchaVerifier) 
                            .then(r => {
                                // SMS sent. Prompt user to type the code from the message, then sign the
                                // user in with confirmationResult.confirm(code).
                                console.log('Received confirmation result');
                                setState({
                                    result: r,
                                    phone: phone,
                                });
                                window.grecaptcha.reset();
                                resolve();
                            }, e => {
                                logActionUserError(ACTIONS.userSignup, `for ${phone} with code ${e.code ?? e.toString()}`);
                                try {
                                    window.grecaptcha.reset();
                                } catch (e) {
                                    console.error('e2', e);
                                }
                                reject(authErrorDecoder(e));
                            });
                    });
            }
        });
    }

    return (
        <FormWrapper
            onSubmit={onSubmit}
            onValidationFailure={logFormValidationFailure}
            className="space-y-6"
        >
            { state ?
            <FormInput
                id="otp"
                name="otp"
                type="text"
                inputMode="numeric"
                autoComplete="one-time-code"
                placeholder="6-digit code"
                label="Enter the 6-digit code sent to your mobile"
                className={`${inputStyles.sizeXL} text-center`}
                required
            />
            :
            <div className="space-y-2">
                {intro}
                <FormInput
                    id="phone"
                    name="phone"
                    type="tel"
                    autoComplete="tel"
                    placeholder="mobile number"
                    label="Mobile number"
                    className={`${inputStyles.sizeXL} text-center`}
                    required
                />
                <div className="text-xs text-fgCard-secondary leading-relaxed">
                    We will only use your mobile number to send you a text message with a code to confirm ownership of the number.
                    We will not call you and do not send marketing texts. We do not share your number with 3rd parties nor divulge
                    it to the organisations receiving your donations.
                </div>
            </div>
            }
            <div className="flex items-center justify-between">
                { !state &&
                <FormCheckBox
                    id="remember_me"
                    name="remember_me"
                    label="Keep me logged in"
                />
                }
            </div>
            <div className="ml-auto mt-4 w-fit" id="gih_recaptchaContainer" style={{ display: state ? "none":"block"}}>
            </div>
            <div className="w-full">
                <FormErrorText/>
                <FormPrimaryButton type="submit">
                    {state ? "Verify code" : "Send verification code"}
                </FormPrimaryButton>
            </div>
        </FormWrapper>
    );
}
