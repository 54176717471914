import {useState, useEffect} from "react";
import {useNavigate} from 'react-router-dom';
import {useSelector, useDispatch} from "react-redux";

import {Combobox, Transition} from "@headlessui/react";

import {useLazyQuery, useQuery} from "@apollo/client";

import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

import {Loading, GlassCard, FeatureCard, ErrorWidget, errorSummaryForUI, graphQLErrorAsText, CampaignCard, LocationSearch} from "gih_web_common";

import {logScreenViewEvent} from "../../utils/analytics";
import {SCREEN_NAME, SCREEN_CLASS} from "../../utils/analyticsConstants";
import {GET_CONFIG} from "../../utils/graphQL/queries";
import {GET_CAMPAIGN_BY_DISTANCE} from "../../utils/graphQL/queries";

import {CampaignModal} from "./modal";

/*
function searchParams2Place(search) {
    const geohash = search.get('geohash');
    const name = search.get('name');
    return (geohash && name) ? {
        geohash: geohash,
        name: name,
        qualifier: null,
    } : null;
}

//const [searchParams, setSearchParameters] = useSearchParams();

setSearchParameters((() => {
    const p = new URLSearchParams();
    p.append('geohash', where.geohash);
    p.append('name', where.name);
    return p;
})());
*/

export default function SearchPage() {

    const dispatch = useDispatch();
    const savedSearch = useSelector(state => state.savedSearch);
    const navigate = useNavigate();

    const [errorState, setErrorState] = useState(null);
    const [config, setConfig] = useState(null);
    const [campaigns, setCampaigns] = useState([]);
    const [selectedCampaign, setSelectedCampaign] = useState(null);

    const { loading: loadingConfig } = useQuery(GET_CONFIG, {
        onCompleted: data => setConfig(data.getConfig),
        onError: e => {
            console.error(graphQLErrorAsText(e));
            setErrorState(errorSummaryForUI(e));
        }
    });

    const [getCampaignsLazy, {loading: loadingCampaigns}] = useLazyQuery(GET_CAMPAIGN_BY_DISTANCE, {
        onCompleted: data => {
            setCampaigns(data.getFilteredCampaignsByDistance);
        },
        onError: e => {
            console.error(graphQLErrorAsText(e));
            setErrorState(errorSummaryForUI(e));
        }
    });

    function onSelectLocation(where) {
        dispatch({ type: "SET_SAVED_SEARCH", payload: where });
    }

    useEffect(() => { // searchParams2Place(searchParams)
        if (savedSearch) {
            console.log('savedSearch', savedSearch);
            setCampaigns([]);
            getCampaignsLazy({
                variables: {
                    geohash: savedSearch.geohash,
                    categories: [],
                    after: new Date().toISOString()
                }
            });
        }
    }, [savedSearch]);

    useEffect(() => {
        logScreenViewEvent(SCREEN_NAME.campaignSearch, SCREEN_CLASS.campaign);
    }, []);

    return (
        <>
            <div className="flex flex-col space-y-4 mx-1 my-2 sm:my-4">

                { errorState ?
                    <GlassCard>
                        <ErrorWidget what={errorState} />
                    </GlassCard>
                : config ?
                    <div className="relative z-10">
                        <FeatureCard feature={{
                            icon: LocationOnOutlinedIcon,
                            title: 'Find causes near you',
                        }} >
                            <LocationSearch
                                initial={savedSearch} //searchParams2Place(searchParams)
                                onSelectLocation={onSelectLocation}
                                placeholder={() => 'Place or postcode'}
                                label="Choose a location near you"
                                Combobox={Combobox}
                                Transition={Transition}
                            />
                        </FeatureCard>
                    </div>
                : null
                }

                { (loadingCampaigns || loadingConfig) &&
                <GlassCard>
                    <Loading size={32}/>
                </GlassCard>
                }

                { campaigns.length > 0 &&
                <div className="flex flex-wrap max-w-screen-2xl mx-auto w-fit gap-4">
                    { campaigns.map(campaign => (
                        <CampaignCard
                            key={campaign._id}
                            campaign={campaign}
                            config={config}
                            onOpenModal={() => setSelectedCampaign(campaign)}
                            onDonate={() => navigate(`/campaign/${campaign._id}`)}
                        />
                    ))}
                </div>
                }

            </div>

            { selectedCampaign &&
            <CampaignModal
                campaign={selectedCampaign}
                config={config}
                onCloseModal={() => setSelectedCampaign(null)}
            />
            }
        </>
    );
}
