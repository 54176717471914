import { setUserId, logEvent } from "firebase/analytics";

import {graphQLErrorAsText} from "gih_web_common";

import { analytics, auth, logAction } from "./firebase";


export const OUTCOMES = {
    success: 'success',
    failed: 'failed',
    failedGraphQL: 'failed_graphql',
    userError: 'user_error',
};

export function setAnalyticsUserId(id) {
    console.log(`Analytics user id set to ${id}`)
    setUserId(analytics, id);
}

export function logScreenViewEvent(screen, screenClass) {
    if (analytics) {
        console.log(`Logging screen view ${screenClass}.${screen}`);
        logEvent(analytics, 'screen_view', {
            firebase_screen: screen,
            firebase_screen_class: screenClass,
        });
    }
}

export function logActionSuccess(what, msg) {
    console.log(`Action ${what} succeeded: ${msg}`);
    logAction(what, OUTCOMES.success, msg);
}

export function logActionUserError(what, msg) {
    console.log(`Action ${what} failed due to user error: ${msg}`);
    logAction(what, OUTCOMES.userError, msg);
}

export function logActionFailed(what, msg) {
    console.log(`Action ${what} failed: ${msg}`);
    logAction(what, OUTCOMES.failed, msg);
}

export function logActionGraphQLFailure(what, e) {
    const msg = graphQLErrorAsText(e);
    console.log(`GraphQL failure for ${what}: ${msg}`);
    logAction(what, OUTCOMES.failedGraphQL, msg);
}

export function logFormValidationFailure(what, e) {
    console.log(`Failed validating ${what}:`, e);
    logAction(`${what}Validate`, OUTCOMES.userError, JSON.stringify(e));
}

export function logFileSuccess(what, file) {
    const details = `name: ${file.name} size: ${file.size} type: ${file.type}`;
    console.log(`Successfully selected ${what} file ${details}`);
    logAction(`${what}Select`, OUTCOMES.success, details);
}

export function logFileFailure(what, e) {
    console.log(`Error selecting ${what} file:`, e);
    logAction(`${what}Select`, OUTCOMES.failed, e.toString());
}
