import React from "react";

import {Modal, TextRender, ActionLink} from "gih_web_common";

import {individualTsAndCs} from '../utils/legals/individualTsAndCs';
import {npoTsAndCs} from '../utils/legals/npoTsAndCs';
import {privacyPolicy} from '../utils/legals/privacyPolicy';
import {cookiePolicy} from '../utils/legals/cookiePolicy';


const buttonLinks = [
    { label: 'Terms & Conditions', target: individualTsAndCs, individual: true },
    { label: 'Terms & Conditions', target: npoTsAndCs, individual: false },
    { label: 'Privacy policy', target: privacyPolicy, individual: null }
];

export function LegalLinks({setModal, individual}) {
    return (
        <div className="sticky bottom-0 flex flex-row gap-4 text-sm bg-bg-default w-fit px-4 py-1 rounded-t-lg">
            { buttonLinks.filter(link => link.individual === null || link.individual === individual).map((link, index) => (
                <ActionLink key={index} onClick={() => setModal(link.target)}>
                    {link.label}
                </ActionLink>
            ))}
        </div>
    );
}

export function LegalModal({modal, setModal}) {
    return (
        <Modal onDismiss={() => setModal(null)} title={modal.title}>
            <div className="w-full">
                { modal.what === 'privacyPolicy' &&
                <ActionLink onClick={() => setModal(cookiePolicy)}>Cookie Policy</ActionLink>
                }
                { modal.what === 'cookiePolicy' &&
                <ActionLink onClick={() => setModal(privacyPolicy)}>Privacy Policy</ActionLink>
                }
            </div>
            <TextRender what={modal.body} />
        </Modal>
    );
}
