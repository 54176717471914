import { combineReducers } from 'redux';

import {individualTsAndCs} from './legals/individualTsAndCs';


function savedSearchReducer(state=null, action) {

    switch (action.type) {

        case 'SET_SAVED_SEARCH':
            state = { ...action.payload };
            break;

        default:
            break;
    }

    return state;
}

function userReducer(state=null, action) {

    switch (action.type) {
        case 'SET_USER':
            state = { ...action.payload };
            delete state.__typename;
            delete state.gender?.__typename;
            delete state.address?.__typename;
            break;

        case 'CLEAR_AUTH':
            state = null;
            break;

        default:
            break;
    }

    return state;
}

function authUserReducer(state=null, action) {

    switch (action.type) {
        case 'SET_AUTH':
            state = { ...action.payload };
            break;

        case 'CLEAR_AUTH':
            state = null;
            break;

        default:
            break;
    }

    return state;
}

function loginStateReducer(state=null, action) {

    switch (action.type) {
        case 'SET_AUTH':
            state = (state === 'SignupBegun' || state === 'SignupAuth') ? 'SignupAuth' : 'SigninAuth';
            break;

        case 'SET_USER':
            state = (!action.payload.acceptedTsAndCs || action.payload.acceptedTsAndCs < individualTsAndCs.version) ? 'ReqTsAndCs' : 'LoggedIn';
            break;

        case 'CLEAR_AUTH':
            state = 'LoggedOut';
            break;

        case 'SIGNUP_BEGIN':
            state = 'SignupBegun';
            break;

        case 'SIGNUP_GATHER_USER_DETAILS':
            state = 'SignupGatherUserDetails';
            break;

        default:
            break;
    }

    return state;
}

function lastDonationReducer(state=null, action) {

    switch (action.type) {

        case 'SET_LAST_DONATION':
            state = { ...action.payload };
            console.log('Setting last donation', state);
            break;

        case 'SET_USER':
            if (state)
                console.log('Clearing last donation');
            state = null;
            break;

        default:
            break;
    }

    return state;
}

export const reduxReducers = combineReducers({
    user: userReducer,
    savedSearch: savedSearchReducer,
    authUser: authUserReducer,
    loginState: loginStateReducer,
    lastDonation: lastDonationReducer,
});
