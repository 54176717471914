import {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {useSelector} from "react-redux";
import {isMobile} from 'react-device-detect';

import {useMutation, useQuery} from '@apollo/client';

import {StickyButton, GlassCard, OnLoadViewer, TextRender, Modal, CampaignDetail, ErrorWidget, errorSummaryForUI, WebLink, ActionLink, buttonStyles} from 'gih_web_common';

import {GET_CONFIG, GET_CAMPAIGN_BY_ID, CREATE_DONATION_INTENT_NO_SIGNUP, CREATE_DONATION_INTENT} from '../../utils/graphQL/queries';
import {individualTsAndCs} from '../../utils/legals/individualTsAndCs';
import {privacyPolicy} from '../../utils/legals/privacyPolicy';
import {cookiePolicy} from '../../utils/legals/cookiePolicy';
import {logScreenViewEvent, logActionGraphQLFailure} from "../../utils/analytics";
import {SCREEN_NAME, SCREEN_CLASS, ACTIONS} from "../../utils/analyticsConstants";
import {company} from "../../utils/legals/constants";

import {CookieBanner} from "../../common/cookieBanner";
import {getFundraiserButtonPath} from "../../common/topBanner";

import {DonationAmountScreen} from './amount';
import {MessageScreen} from './message';
import {GiftAidScreen} from './giftAid';
import {GiftAidConfirmationScreen} from './giftAidDetails';
import {CheckoutScreen} from './checkout';
import {CharityDetailModal} from './charity';


const buttonLinks = [
    { label: 'Terms & Conditions', target: individualTsAndCs },
    { label: 'Privacy policy', target: privacyPolicy }
];

export default function DonationPage() {

    const navigate = useNavigate();
    const { campaignId } = useParams();
    const user = useSelector(state => state.user);

    const [intent, setIntent] = useState(null);
    const [campaign, setCampaign] = useState(null);
    const [config, setConfig] = useState(null);
    const [errorState, setErrorState] = useState(null);

    const [currentStep, setCurrentStep] = useState(0);
    const [buttonState, setButtonState] = useState(null);
    const [modal, setModal] = useState(null);
    const [showCharityDetail, setShowCharityDetail] = useState(false);

    const [name, setName] = useState({
        title: user?.title ?? null,
        first: user?.firstName ?? null,
        last: user?.lastName ?? null,
    });

    const [address, setAddress] = useState({
        line1: user?.address?.line1 ?? '',
        line2: null,
        townCity: user?.address?.townCity ?? '',
        streetName: user?.address?.streetName ?? '',
        postcode: user?.address?.postcode ?? '',
    });

    const [donationAmount, setDonationAmount] = useState(0);
    const [voluntaryAmount, setVoluntaryAmount] = useState(0);

    const [message, setMessage] = useState(null);
    const [hideDonorName, setHideDonorName] = useState(false);

    const [ukTaxPayer, setUKTaxPayer] = useState(false);
    const [isMyOwnMoney, setIsMyOwnMoney] = useState(false);
    const [isNotPartOfSweepstake, setIsNotPartOfSweepstake] = useState(false);

    const { loading: configQueryInProgress } = useQuery(GET_CONFIG, {
        onCompleted: data => setConfig(data.getConfig),
        onError: e => {
            logActionGraphQLFailure(ACTIONS.getConfig, e);
            setErrorState(errorSummaryForUI(e));
        }
    });

    const { loading: campaignQueryInProgress } = useQuery(GET_CAMPAIGN_BY_ID, {
        variables: {
            id: campaignId
        },
        onCompleted: data => {
            const detail = data.findCampaignById;
            if (detail) {
                setCampaign({
                    ...detail.campaign,
                    fundraisers: detail.fundraisers,
                    endDate: new Date(detail.campaign.endDate),
                    status: detail.status,
                });
            } else {
                setCampaign(null);
                setErrorState('campaignNotFound');
            }
        },
        onError: e => {
            logActionGraphQLFailure(ACTIONS.getCampaign, e);
            setErrorState((campaignId.length !== 24) ? 'badPath' : errorSummaryForUI(e));
        }
    });

    const [createDonationIntent] = useMutation(CREATE_DONATION_INTENT);
    const [createDonationIntentNoSignup] = useMutation(CREATE_DONATION_INTENT_NO_SIGNUP);

    function giftAidValid() {
        return isMyOwnMoney
            && isNotPartOfSweepstake
            && address.line1.trim().length > 0
            && address.streetName.trim().length > 0
            && address.townCity.trim().length > 0
            && address.postcode.trim().length > 0;
    }

    function getPaymentIntent() {

        console.log('Requesting payment intent...');
        setIntent(null);
        setButtonState({
            text: 'Preparing checkout...',
            loading: true,
        });

        if (user) {
            return createDonationIntent({
                variables: {
                    intent: {
                        campaignId: campaignId,
                        donation: donationAmount,
                        tip: voluntaryAmount,
                        giftAid: giftAidValid(),
                        title: name.title,
                        firstName: name.first,
                        lastName: name.last,
                        hideDonor: hideDonorName,
                        comment: message,
                        address: giftAidValid() ? address : null,
                        termination: null,
                        paymentDay: null,
                    },
                },
            })
            .then(r => {
                setIntent(r.data.createDonationIntent);
                setErrorState(null);
            }, e => {
                logActionGraphQLFailure(ACTIONS.getPaymentIntent, e);
                setErrorState('checkoutError');
                setButtonState({
                    text: 'Try again',
                    disabled: false,
                });
                throw e;
            });
        
        } else {
            return createDonationIntentNoSignup({
                variables: {
                    intent: {
                        campaignId: campaignId,
                        donation: donationAmount,
                        tip: voluntaryAmount,
                        giftAid: giftAidValid(),
                        title: name.title,
                        firstName: name.first,
                        lastName: name.last,
                        hideDonor: hideDonorName,
                        comment: message,
                        address: giftAidValid() ? address : null,
                    },
                },
            })
            .then(r => {
                setIntent(r.data.createDonationIntentNoSignUp);
                setErrorState(null);
            }, e => {
                logActionGraphQLFailure(ACTIONS.getPaymentIntent, e);
                setErrorState('checkoutError');
                setButtonState({
                    text: 'Try again',
                    disabled: false,
                });
                throw e;
            });
        }
    }

    const onContinue = () => {
        let newStep = currentStep;
        while (newStep < steps.length - 1) {
            newStep += 1;
            if (!steps[newStep].isAvailable || steps[newStep].isAvailable()) {
                const targetStep = newStep;
                return (steps[targetStep].onEntry ? steps[targetStep].onEntry() : Promise.resolve(null))
                    .then(() => {
                        setCurrentStep(targetStep);
                    }, () => {
                        console.log('Failed to move to next step');
                    });
            }
        }
    };

    const onBack = () => {
        if (errorState) {
            setErrorState(null);
            setButtonState(steps[currentStep].button());
        } else {
            let newStep = currentStep;
            while (newStep > 0) {
                newStep -= 1;
                if (!steps[newStep].isAvailable || steps[newStep].isAvailable()) {
                    setCurrentStep(newStep);
                    break;
                }
            }
        }
    };

    const steps = [{
        render: () => (
            <CampaignDetail
                campaign={campaign}
                config={config}
                onShowCharityDetail={() => setShowCharityDetail(true)}
                onCreateFundraiser={() => navigate(getFundraiserButtonPath(campaign))}
            />
        ),
        button: () => {
            if (!campaign) {
                return null;
            } else {
                switch (campaign.status) {
                    case 'unavailable':
                        return {
                            text: 'Sorry - donation unavailable - please try later',
                            disabled: true,
                        }
                    case 'ended':
                        return {
                            text: 'Sorry - this campaign has ended',
                            disabled: true,
                        }
                    case 'active':
                        return {
                            text: 'Donate now',
                            disabled: false,
                        }
                    default:
                        return {
                            text: 'Sorry - something went wrong - please try later',
                            disabled: true,
                        }
                }
            }
        },
        screenClass: SCREEN_CLASS.campaign,
        screenName: SCREEN_NAME.campaignDetail,
    }, {
        render: () => (
            <DonationAmountScreen
                campaign={campaign}
                config={config}
                donationAmount={donationAmount}
                voluntaryAmount={voluntaryAmount}
                setDonationAmount={setDonationAmount}
                setVoluntaryAmount={setVoluntaryAmount}
            />
        ),
        button: () => {
            if (donationAmount > config.donation.maximumAmountGBP * 100) {
                return {
                    text: `Maximum donation is £${config.donation.maximumAmountGBP}`,
                    disabled: true
                }
            } else if (donationAmount < config.donation.minimumAmountGBP * 100) {
                return {
                    text: `Minimum donation is £${config.donation.minimumAmountGBP}`,
                    disabled: true
                }
            } else {
                return {
                    text: 'Continue',
                    disabled: false
                }
            }
        },
        screenClass: SCREEN_CLASS.donation,
        screenName: SCREEN_NAME.donationAmount,
    }, {
        render: () => (
            <MessageScreen
                message={message}
                setMessage={setMessage}
                campaign={campaign}
                name={name}
                setName={setName}
                hideDonorName={hideDonorName}
                setHideDonorName={setHideDonorName}
            />
        ),
        button: () => {
            if (!name.first || !name.last || !name.title) {
                return {
                    text: 'Please enter your name',
                    disabled: true
                }
            } else if (message) {
                return {
                    text: 'Continue',
                    disabled: false
                }
            } else {
                return {
                    text: 'Continue without message',
                    disabled: false
                }
            }
        },
        screenClass: SCREEN_CLASS.donation,
        screenName: SCREEN_NAME.donationMessage,
    }, {
        render: () => (
            <GiftAidScreen
                amount={donationAmount}
                ukTaxPayer={ukTaxPayer}
                setUKTaxPayer={setUKTaxPayer}
                onContinue={onContinue}
            />
        ),
        button: () => null,
        isAvailable: () => campaign.allowGiftAid,
        screenClass: SCREEN_CLASS.donation,
        screenName: SCREEN_NAME.donationGiftAid,
    }, {
        render: () => (
            <GiftAidConfirmationScreen
                isMyOwnMoney={isMyOwnMoney}
                setIsMyOwnMoney={setIsMyOwnMoney}
                isNotPartOfSweepstake={isNotPartOfSweepstake}
                setIsNotPartOfSweepstake={setIsNotPartOfSweepstake}
                address={address}
                setAddress={setAddress}
            />
        ),
        button: () => {
            return {
                text: 'Continue',
                disabled: !giftAidValid()
            }
        },
        isAvailable: () => (campaign.allowGiftAid && ukTaxPayer),
        screenClass: SCREEN_CLASS.donation,
        screenName: SCREEN_NAME.donationGiftAidDetails,
    }, {
        render: () => (
            <CheckoutScreen
                campaign={campaign}
                donationAmount={donationAmount}
                voluntaryAmount={voluntaryAmount}
                config={config}
                intent={intent}
            />
        ),
        button: () => null,
        onEntry: () => getPaymentIntent(),
        screenClass: SCREEN_CLASS.donation,
        screenName: SCREEN_NAME.donationCheckout,
    }];

    useEffect(() => {
        if (!errorState)
            setButtonState(steps[currentStep].button());
    }, [
        errorState,
        currentStep,
        donationAmount,
        message,
        name,
        isMyOwnMoney,
        isNotPartOfSweepstake,
        address,
        campaign
    ]);

    useEffect(() => {
        logScreenViewEvent(steps[currentStep].screenName, steps[currentStep].screenClass);
    }, [currentStep]);

    return (
        <>
            { isMobile && buttonState &&
            <StickyButton wrapperClass="top-0" onClick={onContinue} disabled={buttonState.disabled} loading={buttonState.loading ?? false}>
                {buttonState.text}
            </StickyButton>
            }

            <div className="p-1 sm:p-2">
                <GlassCard width="max-w-screen-2xl">
                    <OnLoadViewer loading={configQueryInProgress || campaignQueryInProgress}>
                        { currentStep > 0 &&
                        <div className="w-fit mr-auto mb-3">
                            <button onClick={onBack} className={buttonStyles.outlineLgNarrow}>
                                Back
                            </button>
                        </div>
                        }
                        <div className="flex flex-col space-y-3 justify-center">
                            { errorState ? <ErrorWidget what={errorState} /> 
                  : (config && campaign) ? steps[currentStep].render()
                                         : null
                            }
                        </div>
                    </OnLoadViewer>

                    <div className="flex flex-row gap-4 text-sm justify-end mt-3">
                        { !user &&
                        <WebLink href={`${company.websiteURL}/donors`}>About {company.tradingName}</WebLink>
                        }
                        { buttonLinks.map((link, index) => (
                            <ActionLink key={index} onClick={() => setModal(link.target)}>{link.label}</ActionLink>
                        ))}
                    </div>
                </GlassCard>
            </div>

            { modal &&
            <Modal onDismiss={() => setModal(null)} title={modal.title}>
                { modal.what === 'privacyPolicy' &&
                <ActionLink onClick={() => setModal(cookiePolicy)}>Cookie Policy</ActionLink>
                }
                { modal.what === 'cookiePolicy' &&
                <ActionLink onClick={() => setModal(privacyPolicy)}>Privacy Policy</ActionLink>
                }
                <TextRender what={modal.body} />
            </Modal>
            }

            { !isMobile && buttonState &&
            <StickyButton wrapperClass="bottom-0" onClick={onContinue} disabled={buttonState.disabled} loading={buttonState.loading ?? false}>
                {buttonState.text}
            </StickyButton>
            }

            { showCharityDetail &&
            <CharityDetailModal config={config} id={campaign.charityId._id} onDismiss={() => setShowCharityDetail(false)} />
            }

            <CookieBanner onShowCookiePolicy={() => setModal(cookiePolicy)} />
        </>
    );
}
