import { useNavigate } from 'react-router-dom';

import NearMeIcon from '@mui/icons-material/NearMe';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';

import {
    GlassPreviewCard,
    GroupBox,
    CustomButton,
    CampaignProgressBar,
    CharitySummaryMini,
    addressToString,
    placesToString,
    buttonStyles,
    monetaryAmountToString,
    dateToStringRelative,
    dateToStringNoTime,
} from "gih_web_common";

import {InAidOfSummaryWidget} from "../../common/donationWidgets";


function Badge({myCampaign}) {

    const newUpdates = myCampaign.updates.filter(update => update.createdAt > myCampaign.lastSeenUpdate);
    let text = null;

    if (newUpdates.length > 1)
        text = `${newUpdates.length} new updates`;
    else if (newUpdates.length > 0)
        text = 'New update';

    return text ?
        <div className="whitespace-nowrap inline px-2 py-1 bg-red-600 display:inline rounded-full items-center text-xs sm:text-sm text-white font-bold">{text}</div>
    :
        null;
}

export function MyCampaignCard({ myCampaign, config, haveFundraiser, ended }) {

    const navigate = useNavigate();

    const campaign = myCampaign.campaign;

    return (
        <GlassPreviewCard
            rmtPrefix={config.imageURLPrefix}
            rmt={campaign.imageURL}
            overlay={<Badge myCampaign={myCampaign} />}
        >
            <GroupBox translucent={true}>
                <div className="flex text-lg justify-center items-center font-bold min-h-[3em]">{campaign.name}</div>
                <div className="w-full">
                    <CampaignProgressBar
                        total={campaign.donations.total}
                        target={campaign.target}
                        supporters={campaign.donations.uniqueDonors}
                    />
                    <div className="text-xs">Campaign ends on {dateToStringNoTime(campaign.endDate)}</div>
                </div>
            </GroupBox>
            <GroupBox>
                <div className="flex space-x-2 items-center text-fgCard-primary">
                    <ThumbUpOutlinedIcon />
                    <span className="text-sm font-bold">
                        You have given a total
                        of {monetaryAmountToString(myCampaign.total)}
                        {myCampaign.giftAidAmount ? `+${monetaryAmountToString(myCampaign.giftAidAmount)} GiftAid` : ''}
                        &nbsp;(last donation {dateToStringRelative(myCampaign.latest)})
                    </span>
                </div>
            </GroupBox>
            <GroupBox>
                <CharitySummaryMini config={config} charity={campaign.charityId} />
            </GroupBox>
            { false &&
            <GroupBox>
                <div className="flex flex-row items-center h-[4em]">
                    <NearMeIcon className="text-fgCard-default"/>
                    <span className="ml-2 text-sm text-ellipsis overflow-hidden max-h-full">
                        {campaign.address ? addressToString(campaign.address) : placesToString(campaign.places)}
                    </span>
                </div>
            </GroupBox>
            }
            <div className="w-full h-full flex flex-col items-center gap-2">
                { !haveFundraiser && !ended && <CustomButton className={buttonStyles.blueLg} onClick={() => navigate(`/fundraiser/${campaign._id}/create`)}>Start a fundraiser</CustomButton> }
                { !ended && <CustomButton className={buttonStyles.primaryLg} onClick={() => navigate(`/campaign/${campaign._id}`)}>Latest news & donate again</CustomButton> }
                { ended && <CustomButton className={buttonStyles.primaryLg} onClick={() => navigate(`/campaign/${campaign._id}`)}>Latest news</CustomButton> }
            </div>
        </GlassPreviewCard>
    );
}

export function MyFundraiserCard({ myFundraiser, config }) {

    const navigate = useNavigate();

    return (
        <GlassPreviewCard
            rmtPrefix={config.imageURLPrefix}
            rmt={myFundraiser.imageURL}
        >
            <GroupBox translucent={true}>
                <div className="flex text-lg justify-center items-center font-bold min-h-[3em]">{myFundraiser.name}</div>
                <div className="w-full">
                    <CampaignProgressBar
                        total={myFundraiser.donations.total}
                        target={myFundraiser.target}
                        supporters={myFundraiser.donations.uniqueDonors}
                    />
                    <div className="text-xs">Fundraiser ends on {dateToStringNoTime(myFundraiser.endDate)}</div>
                </div>
            </GroupBox>
            <GroupBox>
                <InAidOfSummaryWidget config={config} campaign={myFundraiser.inAidOf} />
            </GroupBox>
            { false &&
            <GroupBox>
                <div className="flex flex-row items-center h-[4em]">
                    <NearMeIcon className="text-fgCard-default"/>
                    <span className="ml-2 text-sm text-ellipsis overflow-hidden max-h-full">
                        {myFundraiser.address ? addressToString(myFundraiser.address) : placesToString(myFundraiser.places)}
                    </span>
                </div>
            </GroupBox>
            }
            <div className="w-full h-full flex flex-col items-center gap-2">
                <CustomButton className={buttonStyles.blueLg} onClick={() => navigate(`/fundraiser/${myFundraiser.inAidOf._id}/overview`)}>Make a change or post an update</CustomButton>
                <CustomButton className={buttonStyles.primaryLg} onClick={() => navigate(`/campaign/${myFundraiser._id}`)}>See the latest donations</CustomButton>
            </div>
        </GlassPreviewCard>
    );
}
