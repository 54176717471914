import {useState, useEffect} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import IosShareIcon from '@mui/icons-material/IosShare';

import {CustomButton, buttonStyles} from 'gih_web_common';

import {logActionSuccess, logActionFailed} from "../utils/analytics";


export function ShareOrCopyButton({ details, action }) {

    const [status, setStatus] = useState(null);

    useEffect(() => {
        setStatus((typeof details === Error) ? 'error' : details ? 'ready' : null);
    }, [details]);

    function onShare() {
        return navigator.share(details)
            .then(() => {
                setStatus('shared');
                logActionSuccess(action, 'shared');
            }, e => {
                logActionFailed(action, e);
            });
    }

    function onCopy() {
        setStatus('copied');
        logActionSuccess(action, 'copied');
    }

    return navigator.share ?
        <ShareButton status={status} onClick={onShare} />
    :
        <CopyToClipboard text={details?.url} onCopy={onCopy}>
            <ShareButton status={status} />
        </CopyToClipboard>;
}

function ShareButton({status, onClick}) {
    
    return (
        <CustomButton
            className={buttonStyles.primaryLg}
            onClick={onClick}
            loading={!status}
            disabled={status === 'error'}
        >
            { !status ?
                null
            : (status === 'copied') ? 
                <div>Link copied - click to copy again</div>
            : (status === 'shared') ?
                <div>Link shared - click to share again</div>
            : (status === 'error') ?
                <div>Sharing unavailable</div>
            :
                <>
                    <IosShareIcon className="mr-2" />
                    <div className="flex flex-wrap text-start gap-1">
                        <p>{(status === 'copy') ? "Create an impact - click to copy & share" : "Click to share"}</p>
                    </div>
                </>
            }
        </CustomButton>
    );
}
