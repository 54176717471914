import { useState } from 'react';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import {loadStripe} from "@stripe/stripe-js";

import {StickyButton, monetaryAmountToString, theme} from 'gih_web_common';

import {TotalAmountWidget, CampaignSummaryWidget} from '../../common/donationWidgets';

const appearance = {
    theme: 'flat',
    rules: {
        '.Input:focus': {
            border: `2px solid ${theme.primary.dark}`,
            boxShadow: `0 0 8px ${theme.primary.dark}`,
            outline: 'none',
        },
    },
    variables: {
        colorPrimary: theme.primary.dark,
        colorText: '#30313d',
        colorDanger: '#df1b41',
        fontFamily: 'Ideal Sans, system-ui, sans-serif',
        spacingUnit: '4px',
        borderRadius: '8px',
        colorBackground: '#f5f5f5',
        fontSize: '16px',
    },
};

let stripe = null;

export function CheckoutScreen({ campaign, donationAmount, voluntaryAmount, config, intent }) {

    if (!intent)
        return <div>Preparing checkout...</div>

    if (stripe === null)
        stripe = loadStripe(intent.publishableKey);

    return (
        <Elements stripe={stripe} options={{ clientSecret: intent.paymentIntent, appearance: appearance }}>
            <CheckoutWidget
                campaign={campaign}
                donationAmount={donationAmount}
                voluntaryAmount={voluntaryAmount}
                config={config}
            />
        </Elements>
    )
}

function CheckoutWidget({ campaign, donationAmount, voluntaryAmount, config }) {

    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const totalAmount = donationAmount + voluntaryAmount;

    const onSubmit = async (e) => {
        e.preventDefault();

        setIsLoading(true);
        setMessage(null);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${window.location.origin}/outcome/${campaign._id.toString()}`,
            },
        });

        setMessage(error?.message || 'An unexpected error occurred.');
        setIsLoading(false);
    };

    return (
        <>
            <CampaignSummaryWidget campaign={campaign} config={config} />
            <TotalAmountWidget donationAmount={donationAmount} voluntaryAmount={voluntaryAmount} />

            <form onSubmit={onSubmit} className="flex flex-col gap-2">
                <div className="block font-semibold text-md">
                    Payment details
                </div>
                <div className="bg-white border rounded-lg p-4">
                    <PaymentElement id="payment-element" />
                </div>
                { message &&
                <div id="payment-message" className="text-fgCard-primary font-bold">{message}</div>
                }
                <StickyButton wrapperClass="bottom-2 rounded-lg" type="submit" disabled={isLoading || !stripe || !elements} loading={isLoading}>
                    {isLoading ? 'Processing...' : `Pay ${monetaryAmountToString(totalAmount, true)} now`}
                </StickyButton>
            </form>
        </>
    );
}
