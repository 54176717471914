import {useState} from "react";
import {useSelector} from "react-redux";

import colors from 'tailwindcss/colors';

import AddIcon from '@mui/icons-material/Add';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';

import {useMutation} from '@apollo/client';

import {ModalForm, Video, ImageVCentre, FormInput, FormTextarea, FormPrimaryButton, nameStr} from "gih_web_common";

import { ADD_FUNDRAISER_UPDATE, UPDATE_FUNDRAISER_UPDATE, DELETE_FUNDRAISER_UPDATE, evictAllDependentOnCampaignUpdates } from '../../utils/graphQL/personal';
import { upload } from '../../utils/firebase';
import {logScreenViewEvent, logActionSuccess, logActionGraphQLFailure, logFormValidationFailure, logFileSuccess, logFileFailure} from "../../utils/analytics";
import {SCREEN_NAME, SCREEN_CLASS,  ACTIONS, FILES} from "../../utils/analyticsConstants";

import { VideoFileSelector, PhotoFileSelector } from '../../common/mediaPicker';


const typeOptions = [
    {
        value: "progress",
        label: "A general progress update that everyone can see",
        title: "Fundraising progress update",
        colour: "text-green-500",
        defaultHeading: "",
    }, {
        value: "thanks",
        label: "A donor thank you message",
        title: "Thank you message",
        colour: "text-yellow-500",
        defaultHeading: "Thank you for helping me help others!",
    }
];

export function modelUpdate(fundraiser, type) {
    const opts = typeOptions.find(opts => opts.value === type);
    return {
        _id: null,
        type: opts.value,
        heading: opts.defaultHeading,
        videoName: null,
        imageName: null,
        text: "",
        campaignId: fundraiser._id,
    };
}

function getFormValues(initial) {
    return {
        heading: initial.heading,
        message: initial.text,
    };
}

async function validate(values, initial) {

    console.log("Validating:", values);

    if (initial.type === "thanks" && !initial.videoName && !values.videoFile)
        throw "You must upload a video message when thanking donors.";

    if (!initial.imageName && !values.imageFile && !initial.videoName && !values.videoFile)
        throw "Please upload a picture or video to bring your update to life!";

    const update = {
        type: initial.type,
        postedBy: values.postedBy,
        heading: values.heading,
        text: values.message,
        imageName: initial.imageName,
        videoName: initial.videoName,
        threshold: (initial.type === "thanks") ? 1 : null,
    };

    await upload(values.imageFile, 'campaign_update', 'primary', FILES.fundraiserUpdateImage, fullPath => { update.imageName = fullPath });
    await upload(values.videoFile, 'campaign_update', 'primary', FILES.fundraiserUpdateVideo, fullPath => { update.videoName = fullPath });

    console.log("Fundraiser update:", update);

    return update;
}

export function FormGroup({children, title, colour}) {

    const dark = false;

    return (
        <div className="px-1 py-2 rounded-lg" style={{
            backgroundColor: colors[colour][dark ? '700' : '100'],
            color: dark ? colors.white : colors.black,
        }}>
            <div className="text-xs leading-tight italic pb-2">
                {title}
            </div>
            <div className="p-2 rounded-lg space-y-1" style={{
                backgroundColor: colors[colour][dark ? '600' : '200']
            }}>
                {children}
            </div>
        </div>
    );
}

export function CreateOrEditFundraiserUpdate({config, initial, onComplete}) {

    const user = useSelector(state => state.user);

    const [formValues, setFormValues] = useState(getFormValues(initial));
    const [imageFile, setImageFile] = useState(null);
    const [imageLocalURL, setImageLocalURL] = useState(null);
    const [videoFile, setVideoFile] = useState(null);
    const [videoLocalURL, setVideoLocalURL] = useState(null);

    const [addUpdate] = useMutation(ADD_FUNDRAISER_UPDATE, { update: evictAllDependentOnCampaignUpdates });
    const [updateUpdate] = useMutation(UPDATE_FUNDRAISER_UPDATE, { update: evictAllDependentOnCampaignUpdates });

    async function onSubmit() {

        const update = await validate(getValues(), initial);

        let mutation;
        let action;

        if (initial._id) {
            action = ACTIONS.fundraiserUpdateUpdate;
            mutation = updateUpdate({
                variables: {
                    id: initial._id,
                    update: update,
                }
            });
        } else {
            action = ACTIONS.fundraiserUpdateCreate;
            mutation = addUpdate({
                variables: {
                    campaignId: initial.campaignId,
                    update: update,
                }
            });
        }

        return mutation
            .then(r => {
                logActionSuccess(action, 'Fundraiser update');
                onComplete(true);
            }, e => {
                logActionGraphQLFailure(action, e);
                throw e;
            });
    }
    
    function getValues() {
        return {
            ...formValues,
            postedBy: nameStr(user),
            imageFile: imageFile,
            videoFile: videoFile,
        }
    }

    function onSelectVideo(file) {
        try {
            setVideoFile(file);
            setVideoLocalURL(URL.createObjectURL(file));
            logFileSuccess(FILES.campaignUpdateVideo, file);
        } catch (e) {
            logFileFailure(FILES.campaignUpdateVideo, e);
        }
    }

    function onSelectImage(file) {
        try {
            setImageFile(file);
            setImageLocalURL(URL.createObjectURL(file));
            logFileSuccess(FILES.campaignUpdateImage, file);
        } catch (e) {
            logFileFailure(FILES.campaignUpdateImage, e);
        }
    }

    const opts = typeOptions.find(opts => opts.value === initial.type);

    return (
        <ModalForm
            title={opts.title}
            onDismiss={() => onComplete(false)}
            onSubmit={onSubmit}
            initialValues={getFormValues(initial)}
            onValueChange={values => setFormValues(values)}
            onValidationFailure={logFormValidationFailure}
            footer={
                <FormPrimaryButton type="submit">
                    { initial._id ? <span><ModeEditOutlineIcon/> Save changes</span> : <span><AddIcon/> Add</span> }
                </FormPrimaryButton>
            }
        >
            <FormGroup colour="sky" title="What do you want to say?">
                <FormInput
                    id="heading"
                    name="heading"
                    length={formValues.heading.length}
                    maxLength={config.campaign.updateHeadingMaxLength}
                    type="text"
                    placeholder="Summary heading..."
                    label="Heading"
                    required
                    rootClassName="w-full"
                />
                <FormTextarea
                    rows={5}
                    id="message"
                    name="message"
                    length={formValues.message.length}
                    maxLength={config.campaign.updateTextMaxLength}
                    type="text"
                    placeholder="Type your update here..."
                    label="Message"
                    required
                    rootClassName="w-full"
                />
            </FormGroup>

            <div className="flex flex-wrap gap-2 mx-auto lg:px-1 md:max-w-none grid grid-cols-1 md:grid-cols-2">
                { initial.type === "progress" &&
                <div className="mx-auto h-fit p-2 bg-bg-secondary rounded-lg">
                    <PhotoFileSelector editing={initial.imageName || imageLocalURL} onSelectFile={onSelectImage} />
                    <ImageVCentre
                        rmtPrefix={config.imageURLPrefix}
                        rmt={initial.imageName}
                        local={config.imageLocalURL}
                        what="imagePreview"
                        placeholderSeverity="info"
                        placeholderText="Optional photo."
                    />
                </div>
                }
                <div className="mx-auto h-fit p-2 bg-bg-secondary rounded-lg">
                    <VideoFileSelector editing={initial.videoName || videoLocalURL} maxSize={100} onSelectFile={onSelectVideo} />
                    <Video
                        rmtPrefix={config.imageURLPrefix}
                        rmt={initial.videoName}
                        local={videoLocalURL}
                        what="imagePreview"
                        placeholderSeverity={initial.type === "thanks" ? "warning" : "info"}
                        placeholderText={`${initial.type === "thanks" ? "For thank you messages a video is required" : "Optional video"} - up to 30s duration.`}
                    />
                </div>
            </div>
        </ModalForm>
    );
}
