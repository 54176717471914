export const ACTIONS = {
    getConfig: 'GetConfig',
    getCampaign: 'GetCampaign',
    getCharity: 'GetCharity',
    getPaymentIntent: 'GetPaymentIntent',
    shareCampaign: 'ShareCampaign',

    fundraiserUpdateCreate: 'FundraiserUpdateCreate',
    fundraiserUpdateUpdate: 'FundraiserUpdateUpdate',

    userAuthenticate: 'UserAuthenticate',
    userLogin: 'UserLogin',
    userLogout: 'UserLogout',
    userSignup: 'UserSignup',
};

export const SCREEN_CLASS = {
    campaign: "Campaign",
    charity: "Charity",
    donation: "Donation",
    user: "User",
};

export const SCREEN_NAME = {
    // Campaign.
    campaignDiscover: "CampaignDiscover",
    campaignSearch: "CampaignSearch",
    campaignDetail: "CampaignDetail",
    myCampaigns: "MyCampaigns",

    // Charity.
    charityDetail: "CharityDetail",

    // Donation.
    donationAmount: "DonationAmount",
    donationMessage: "DonationMessage",
    donationGiftAid: "DonationGiftAid",
    donationGiftAidDetails: "DonationGiftAidDetails",
    donationCheckout: "DonationCheckout",
    donationSuccess: "DonationSuccess",

    myDonations: "MyDonations",

    // User.
    myProfile: "MyProfile",
    fundraiser: "Fundraiser",
};

export const FILES = {
    fundraiserImage: 'FundraiserImage',
    fundraiserVideo: 'FundraiserVideo',
    fundraiserUpdateImage: 'FundraiserUpdateImage',
    fundraiserUpdateVideo: 'FundraiserUpdateVideo',
};
