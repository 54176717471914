import Cookies from 'js-cookie';

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { ref, uploadBytes, getStorage } from "firebase/storage";

import { v4 as uuidv4 } from 'uuid';

const cookiesAcceptedCookie = 'cookies_accepted';
const cookiesRejectedCookie = 'cookies_rejected';

export function setCookieAcceptanceState(accepted) {
    if (accepted) {
        Cookies.set(cookiesAcceptedCookie, 'true', { expires: 365 }); // Set cookie for 1 year
       startAnalytics()
    } else {
        Cookies.set(cookiesRejectedCookie, 'true', { expires: 5 }); // Set cookie for 5 days
    }
}

export function getCookieAcceptanceState() {
    if (Cookies.get(cookiesAcceptedCookie)) {
        return true;
    } else if (Cookies.get(cookiesRejectedCookie)) {
        return false;
    } else {
        return null;
    }
}

const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);
const firebaseApp = initializeApp(firebaseConfig);

export const auth = getAuth(firebaseApp);
export const storage = getStorage(firebaseApp);

export let analytics = null;

function startAnalytics() {
    console.log('Starting analytics...');
    analytics = getAnalytics(firebaseApp);
}

if (getCookieAcceptanceState() === true) {
    startAnalytics();
}

export function logAction(what, outcome, text) {
    if (analytics) {
        logEvent(analytics, 'user_action', {
            what: what,
            outcome: outcome,
            text: text.substring(0, 100),
            text2: text.substring(100),
        });
    }
}

export async function upload(file, entity, element, what, onSuccess) {

    if (file) {
        if (!file.name)
            throw `The ${what} file has no name`;

        const extension = file.name.substring(file.name.lastIndexOf('.'));
        const imageUniqueId = uuidv4();
        const target = entity + "_" + imageUniqueId + "_" + element + extension;

        console.log(`Uploading ${file.name} with extension ${extension} as ${target}`);

        const storageRef = ref(storage, target);
        const action = `${what}Upload`;

        return uploadBytes(storageRef, file)
            .then(snapshot => {
                const text = `${what} uploaded as ${snapshot.ref.fullPath}`;
                console.log(text);
                logAction(action, 'success', text);
                onSuccess(snapshot.ref.fullPath)
            }).catch(e => {
                logAction(action, 'failed', e.toString());
                throw `Sorry - something went wrong - please press "Save" again to retry.`
            });
    }
}
