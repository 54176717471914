import {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {DateTime} from "luxon";

import {useQuery} from "@apollo/client";

import {GlassCard, OnLoadViewer, monetaryAmountToString, buttonStyles} from 'gih_web_common';

import {logScreenViewEvent} from "../../utils/analytics";
import {SCREEN_NAME, SCREEN_CLASS} from "../../utils/analyticsConstants";
import {GET_CONFIG} from "../../utils/graphQL/queries";
import {GET_MY_DONATIONS} from "../../utils/graphQL/personal";

import {MyDonationCard} from './card';


function fixup(myDonation) {
    return {
        ...myDonation,
        when: DateTime.fromISO(myDonation.when),
    };
}

function makeSummary(myDonations) {
    const summary = {
        total: 0,
        totalGiftAided: 0,
    };
    myDonations.forEach(myDonation => {
        summary.total += myDonation.amount;
        summary.totalGiftAided += myDonation.giftAidAmount ? myDonation.giftAidAmount : 0;
    });
    return summary;
}

export default function MyCausesPage() {

    return (
        <>
            <TaxYear taxYear={2024} />
            <TaxYear taxYear={2023} />
        </>
    );
}

function TaxYear({taxYear}) {

    const user = useSelector(state => state.user);

    const [myDonations, setDonations] = useState([]);
    const [summary, setSummary] = useState(null);

    const { loading: configInProgress, data: configData, error: configError } = useQuery(GET_CONFIG);

    const {loading, error} = useQuery(GET_MY_DONATIONS, {
        variables: {
            userId: user._id,
            taxYear: taxYear,
        },
        notifyOnNetworkStatusChange: true,
        onCompleted: data => {
            setDonations(data.findDonationsByUserId.map(myDonation => fixup(myDonation)));
            setSummary(makeSummary(data.findDonationsByUserId));
        },
    });

    const config = configData?.getConfig;
    const reportName = user.downloadTokens.find(token => token.name === `giving_tax_year_${taxYear}`)?.value;

    useEffect(() => {
        logScreenViewEvent(SCREEN_NAME.myDonations, SCREEN_CLASS.donation);
    }, []);

    return (
        <OnLoadViewer loading={loading || configInProgress} error={error ?? configError}>
            <div className="bg-primary-dark text-white text-2xl font-bold py-2 px-4">
                 {(taxYear === 2024) ? "Current Tax Year" : `Tax Year ${taxYear}-${taxYear+1}`}
            </div>
            { myDonations && summary &&
            <div className="p-1 sm:p-4 space-y-3">
                <GlassCard width="w-fit space-y-3">
                    <div className="flex flex-row items-center space-x-2">
                        <span>Total donated so far this year</span><span className="text-3xl font-bold">{monetaryAmountToString(summary.total)}</span>
                    </div>
                    <div className="flex flex-row items-center space-x-2">
                        <span>Total GiftAid added so far this tax year</span><span className="text-3xl font-bold">{monetaryAmountToString(summary.totalGiftAided)}</span>
                    </div>
                    { reportName &&
                    <>
                        <div className="font-bold">Click to get a statement of your donations so far this tax year</div>
                        <a target="_blank" rel="noreferrer" className={buttonStyles.primaryLg} href={config.certURLPrefix + 'giving/' + reportName}>Download</a>
                    </>
                    }
                </GlassCard>
                <div className="flex flex-wrap gap-2 w-fit mx-auto">
                    { myDonations.map(myDonation =>
                    <MyDonationCard
                        key={myDonation._id}
                        config={config}
                        myDonation={myDonation}
                    />
                    )}
                </div>
            </div>
            }
        </OnLoadViewer>
    );
}
