import React from "react";
import {useNavigate} from 'react-router-dom';

import {GlassModal, CustomButton, CampaignDetail, buttonStyles} from "gih_web_common";


export function CampaignModal({ campaign, config, onCloseModal }) {

    const navigate = useNavigate();

    return (
        <GlassModal width="max-w-screen-2xl">   
            <CampaignDetail campaign={campaign} config={config}/>
            <div className="sticky bottom-0 p-2 ml-auto flex flex-row space-x-1 sm:space-x-3">
                <button className={buttonStyles.primaryLgNarrow} onClick={() => navigate(`/campaign/${campaign._id}`)}>Donate now</button>
                { !campaign.inAidOf && campaign.fundraiserId &&
                <button className={buttonStyles.blueLgNarrow} onClick={() => navigate(`/fundraiser/${campaign._id}/overview`)}>Go to your fundraiser</button>
                }
                { !campaign.inAidOf && !campaign.fundraiserId &&
                <button className={buttonStyles.blueLgNarrow} onClick={() => navigate(`/fundraiser/${campaign._id}/create`)}>Start a fundraiser</button>
                }
                <CustomButton className={buttonStyles.redLgNarrow} onClick={onCloseModal}>Close</CustomButton>
            </div>
        </GlassModal>
    );
}
