import React from 'react';
import {createRoot} from 'react-dom/client';
import {BrowserRouter, Route, Routes, Navigate, useLocation} from 'react-router-dom';
import {Provider, useSelector} from "react-redux";
import {createStore} from "redux";

import {ApolloClient, ApolloProvider, createHttpLink, InMemoryCache} from '@apollo/client';
import {setContext} from "@apollo/client/link/context";

import {Loading} from 'gih_web_common';

import './index.css';

import DonationPage from './pages/donate/page';
import DonationOutcomePage from './pages/outcome/page';
import MyCausesPage from './pages/myCauses/page';
import MyDonationsPage from './pages/myDonations/page';
import SearchPage from './pages/search/page';
import DiscoverPage from './pages/search/page2';
import SignInPage from './pages/sign-in/page';
import SignOutPage from './pages/sign-out/page';
import MyProfilePage from './pages/myProfile/page';
import CreateOrEditFundraiserPage from './pages/fundraiserEdit/page';
import FundraiserOverviewPage from './pages/fundraiserOverview/page';
import {ErrorPage} from "./pages/errorPage";

import TopBanner from "./common/topBanner";
import {auth} from "./utils/firebase";
import {reduxReducers} from "./utils/redux-reducers";

const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_SERVER
});

function allowSignIn() {
    return process.env.REACT_APP_ALLOW_LOGIN === 'true';
}

const pageContext = setContext(async (_, { headers }) => {

    const r = {
        headers: {
            ...headers,
            'x-gih-page': document.location.href,
            'x-gih-referrer': document.referrer,
        }
    };

    if (allowSignIn()) {
        const idToken = await auth.currentUser?.getIdTokenResult();
        if (idToken)
            r.headers.authorization = `Bearer ${idToken.token}`;
    }

    return r;
});

const client = new ApolloClient({
    link: pageContext.concat(httpLink),
    cache: new InMemoryCache(),
});

// Setup react-redux.
const store = createStore(reduxReducers);

// Use createRoot instead of ReactDOM.render
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <Provider store={store}>
                <BrowserRouter>
                    <div className="h-screen max-h-screen w-full overflow-y-auto">
                        <TopBanner />
                        <Body />
                    </div>
                </BrowserRouter>
            </Provider>
        </ApolloProvider>
    </React.StrictMode>
);

function RequireAuth({children}) {

    const loginState = useSelector(state => state.loginState);
    const location = useLocation();

    if (loginState === 'LoggedIn')
        return children;
    else if (loginState !== null && loginState !== 'SigninAuth')
        return <Navigate to="/sign-in" state={{ target: location.pathname }} />;
    else
        return null;
}

function Body() {

    const loginState = useSelector(state => state.loginState);

    return (
        <Routes>
            <Route path="/fundraiser/:campaignId/create" element={<RequireAuth><CreateOrEditFundraiserPage/></RequireAuth>} />
            <Route path="/fundraiser/:campaignId/edit" element={<RequireAuth><CreateOrEditFundraiserPage/></RequireAuth>} />
            <Route path="/fundraiser/:campaignId/overview" element={<RequireAuth><FundraiserOverviewPage/></RequireAuth>} />
            <Route path="/myCauses" element={<RequireAuth><MyCausesPage/></RequireAuth>} />
            <Route path="/myDonations" element={<RequireAuth><MyDonationsPage/></RequireAuth>} />

            { (loginState === 'LoggedIn' || loginState === 'ReqTsAndCs' || loginState === 'SignupGatherUserDetails') ?
            <Route path="/myProfile" element={<MyProfilePage />} />
            : (loginState !== null) ?
            <Route path="/myProfile" element={<Navigate replace to="/sign-in" />} />
            :
            null
            }

            { (loginState !== null && loginState !== 'SigninAuth') ?
            <>
                <Route path="/campaign/:campaignId" element={<DonationPage/>} />
                <Route path="/outcome/:campaignId" element={<DonationOutcomePage/>} />
                <Route path="/search" element={<SearchPage/>} />
                <Route path="/discover" element={<DiscoverPage/>} />
                <Route path="/sign-out" element={<SignOutPage/>} />
                <Route path="/sign-in" element={<SignInPage/>} />
                <Route path="/" element={<Navigate replace to={(loginState === 'LoggedIn') ? "/myCauses" : "/discover"} />} />
                <Route path="*" element={<ErrorPage what="badPath" />} />
            </>
            :
                <Route path="*" element={<div className="py-10 mx-auto w-fit"><Loading size={60} /></div>} />
            }
        </Routes>
    );
}
