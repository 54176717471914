import { useState } from "react";

import {CookieBannerElement} from "gih_web_common";

import {getCookieAcceptanceState, setCookieAcceptanceState} from "../utils/firebase";

export function CookieBanner({onShowCookiePolicy}) {

    const [cookiesAccepted, setCookiesAccepted] = useState(getCookieAcceptanceState);

    const onAcceptCookies = () => {
        setCookieAcceptanceState(true)
        setCookiesAccepted(true);
    };

    const onRejectCookies = () => {
        setCookieAcceptanceState(false)
        setCookiesAccepted(false);
    };

    if (cookiesAccepted !== null)
        return null;

    return <CookieBannerElement
        onShowCookiePolicy={onShowCookiePolicy}
        onAcceptCookies={onAcceptCookies}
        onRejectCookies={onRejectCookies}
    >
        Your privacy is important to us. We use essential cookies served by our payment partner Stripe to ensure the security of your donation.
        We use optional cookies solely for gathering anonymous analytics data to improve our platform. We do not use cookies for marketing or
        ad targeting.
    </CookieBannerElement>
}
