import { FileSelector, buttonStyles } from 'gih_web_common';


export function VideoFileSelector({editing, what="video", maxSize=140, alt=false, onSelectFile}) {
    return <FileSelector
        editing={editing}
        text={`Please choose ${editing ? "new" : ""} a ${what} (supported formats: MP4 and MOV)`}
        what={what}
        buttonClass={alt ? buttonStyles.altLg : buttonStyles.primaryLg}
        accept=".mov, .mp4"
        maxSize={maxSize}
        onSelectFile={onSelectFile}
    />
}

export function PhotoFileSelector({editing, what="photo", alt=false, onSelectFile}) {
    return <FileSelector
        editing={editing}
        text={`Please choose ${editing ? "new" : ""} a ${what} (supported formats: JPG, JPEG and PNG)`}
        what={what}
        buttonClass={alt ? buttonStyles.altLg : buttonStyles.primaryLg}
        accept=".jpg, .jpeg, .png"
        maxSize={10}
        onSelectFile={onSelectFile}
    />
}
