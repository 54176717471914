import {useEffect, useState, Fragment} from 'react';
import {useSelector} from "react-redux";
import {DateTime} from "luxon";
import {useQuery} from "@apollo/client";

import {OnLoadViewer} from 'gih_web_common';

import {SectionHeader} from "../../common/donationWidgets";
import {logScreenViewEvent} from "../../utils/analytics";
import {SCREEN_NAME, SCREEN_CLASS} from "../../utils/analyticsConstants";
import {GET_CONFIG} from "../../utils/graphQL/queries";
import {GET_MY_CAUSES, GET_MY_FUNDRAISERS} from "../../utils/graphQL/personal";

import {MyCampaignCard, MyFundraiserCard} from './card';


function fixup(myCampaign) {
    return {
        _id: myCampaign._id,
        count: myCampaign.count,
        giftAidAmount: myCampaign.giftAidAmount,
        total: myCampaign.total,
        lastSeenUpdate: DateTime.fromISO(myCampaign.lastSeenUpdate),
        latest: DateTime.fromISO(myCampaign.latest),
        campaign: {
            ...myCampaign.campaign,
            endDate: DateTime.fromISO(myCampaign.campaign.endDate),
        },
        updates: myCampaign.updates.map(update => ({
            ...update,
            createdAt: DateTime.fromISO(update.createdAt),
        })),
    };
}

export default function MyCausesPage() {

    const user = useSelector(state => state.user);

    const [campaignLists, setCampaignLists] = useState([[], []]);

    const { loading: configLoading, data: configData, error: configError } = useQuery(GET_CONFIG);
    const config = configData?.getConfig;

    const now = DateTime.now().endOf('minute');

    const {loading, error} = useQuery(GET_MY_CAUSES, {
        variables: {
            userId: user._id,
        },
        notifyOnNetworkStatusChange: true,
        onCompleted: data => {
            const ongoing = [];
            const past = [];
            data.findMyCampaignsByUserId.map(myCampaign => fixup(myCampaign)).forEach(myCampaign => {
                if (myCampaign.campaign.endDate > now)
                    ongoing.push(myCampaign);
                else
                    past.push(myCampaign);
            });
            console.log(ongoing);
            setCampaignLists([ongoing, past]);
        }
    });

    const {loading: fundraisersLoading, data: fundraiserData, error: fundraiserError} = useQuery(GET_MY_FUNDRAISERS);
    const myFundraisers = fundraiserData?.findOwnFundraisers;

    useEffect(() => {
        logScreenViewEvent(SCREEN_NAME.myCampaigns, SCREEN_CLASS.campaign);
    }, []);

    return (
        <OnLoadViewer loading={loading || fundraisersLoading || configLoading} error={error ?? fundraiserError ?? configError}>
            { myFundraisers?.length > 0 &&
            <>
                <SectionHeader>My fundraisers</SectionHeader>
                <div className="flex flex-wrap gap-2 p-1 sm:p-4 w-fit mx-auto">
                    { myFundraisers.map(fundraiser => <MyFundraiserCard key={fundraiser._id} config={config} myFundraiser={fundraiser} />) }
                </div>
            </>
            }
            { campaignLists.map((myCampaigns, index) => (myCampaigns.length > 0) ?
            <Fragment key={index}>
                <SectionHeader>{index ? "Past causes" : "Ongoing causes"}</SectionHeader>
                <div className="flex flex-wrap gap-2 p-1 sm:p-4 w-fit mx-auto">
                    { myCampaigns.map(myCampaign =>
                    <MyCampaignCard
                        key={myCampaign._id}
                        config={config}
                        myCampaign={myCampaign}
                        haveFundraiser={myFundraisers?.find(fundraiser => fundraiser.inAidOf._id === myCampaign._id)}
                        ended={index>0}
                    />
                    )}
                </div>
            </Fragment>
            : null)}
        </OnLoadViewer>
    );
}
