import React from "react";

import {ErrorWidget} from "gih_web_common";


export function ErrorPage({what}) {

    return (
        <div className="m-1 sm:m-2">
            <ErrorWidget what={what} />
        </div>
    );
}
