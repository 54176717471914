import {inputStyles, GroupBox, Checkbox} from "gih_web_common";


export function GiftAidConfirmationScreen({ isMyOwnMoney, setIsMyOwnMoney, isNotPartOfSweepstake, setIsNotPartOfSweepstake, address, setAddress }) {

    return (
        <>
            <GroupBox>
                <h1 className="text-xl font-semibold">Please read and accept these statements</h1>
                <Checkbox
                    label="This is my own money. I am not paying in donations made by a third party, e.g. money collected at an event, the pub, a company donation or a donation from a friend or family member."
                    isChecked={isMyOwnMoney}
                    onChange={() => setIsMyOwnMoney(!isMyOwnMoney)}
                />
                <Checkbox
                    label="This donation is not made as part of a sweepstake, raffle or lottery and I am not receiving anything in return for it, e.g. book, auction prize, ticket to an event."
                    isChecked={isNotPartOfSweepstake}
                    onChange={() => setIsNotPartOfSweepstake(!isNotPartOfSweepstake)}
                />
            </GroupBox>

            { setIsMyOwnMoney && isNotPartOfSweepstake &&
            <GroupBox>
                <AddressFields
                    address={address}
                    setAddress={setAddress}
                />
            </GroupBox>
            }
        </>
    );
};

function AddressFields({ address, setAddress }) {

    const updateAddress = (field, value) => {
        setAddress((prevAddress) => ({
            ...prevAddress,
            [field]: value,
        }));
    };

    return (
        <>
            <h1 className="text-xl font-semibold">Please enter your address</h1>
            <div>
                <label className="block mb-2 font-semibold" htmlFor="houseNumber">House name/number</label>
                <input
                    type="text"
                    id="houseNumber"
                    className={`w-full ${inputStyles.text}`}
                    value={address.line1}
                    onChange={(e) => updateAddress('line1', e.target.value)}
                />
            </div>
            <div>
                <label className="block mb-2 font-semibold" htmlFor="streetName">Street name</label>
                <input
                    type="text"
                    id="streetName"
                    className={`w-full ${inputStyles.text}`}
                    value={address.streetName}
                    onChange={(e) => updateAddress('streetName', e.target.value)}
                />
            </div>
            <div>
                <label className="block mb-2 font-semibold" htmlFor="townCity">Town/city</label>
                <input
                    type="text"
                    id="townCity"
                    className={`w-full ${inputStyles.text}`}
                    value={address.townCity}
                    onChange={(e) => updateAddress('townCity', e.target.value)}
                />
            </div>
            <div>
                <label className="block mb-2 font-semibold" htmlFor="postcode">Postcode</label>
                <input
                    type="text"
                    id="postcode"
                    className={`w-full ${inputStyles.text}`}
                    value={address.postcode}
                    onChange={(e) => updateAddress('postcode', e.target.value.toUpperCase())}
                />
            </div>
        </>
    );
};
