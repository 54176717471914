import {monetaryAmountToString, Checkbox, GroupBox, GroupBox2, buttonStyles} from "gih_web_common";


export function GiftAidScreen({amount, onContinue, ukTaxPayer, setUKTaxPayer}) {

    return (
        <GroupBox>
            <h1 className="text-2xl font-semibold">Your donation could be worth 25% more at no cost to you with Gift Aid!</h1>

            <div className="rounded-lg">
                <div className="p-6 bg-gradient-to-br from-primary-dark to-primary-darker rounded-lg">
                    <p className="text-xl mb-2 text-center">Your donation {monetaryAmountToString(amount, true)}</p>
                    <p className="text-2xl font-bold text-center">with Gift Aid {monetaryAmountToString(amount * 5 / 4, true)}</p>
                </div>
            </div>

            <GroupBox2>
                <div>Please agree to the following statement to confirm that your donation is eligible for GiftAid:</div>
                <Checkbox
                    label="I am a UK taxpayer and understand that if I pay less Income Tax and/or Capital Gains Tax than the amount of Gift Aid claimed on all my donations in that tax year, it is my responsibility to pay any difference."
                    isChecked={ukTaxPayer}
                    onChange={() => setUKTaxPayer(!ukTaxPayer)}
                />
            </GroupBox2>

            <div className="flex justify-between">
                <button
                    onClick={onContinue}
                    className={`${buttonStyles.primaryLgNarrow} w-[40%]`}
                >
                    Skip Gift Aid
                </button>
                <button
                    onClick={onContinue}
                    disabled={!ukTaxPayer}
                    className={`${buttonStyles.primaryLgNarrow} w-[40%]`}
                >
                    Add Gift Aid
                </button>
            </div>
        </GroupBox>
    );
}
